import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

let cameraDate
let cameraTime
let cameraTime2

  const SettingsCamera = (props) =>  {

    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')
    let data=props.data

    const [cameraLink,setCameraLink]= useState(null)

    useEffect(() => {

      setCameraLink(props.data.videoFeedURL)

      cameraDate=null
      cameraTime=null
      cameraTime2=null


      if (data.lastTimeLotGotOccupied!=null && data.occupied==true) {
  
        let date = new Date(data.lastTimeLotGotOccupied)
        let date2 = new Date()
      
        cameraDate=('0'+ date.getDate()).slice(-2) + "." + ('0'+ (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear()
        cameraTime=('0'+date.getHours()).slice(-2)+":"+('0'+date.getMinutes()).slice(-2)+":"+('0'+date.getSeconds()).slice(-2)
      
           let diffMS = Math.abs(date2 - date);
      
           let days = Math.floor(diffMS / (24*60*60*1000));
           let daysms=diffMS % (24*60*60*1000);
           let hours = Math.floor((daysms)/(60*60*1000));
           let hoursms=diffMS % (60*60*1000);
           let minutes = Math.floor((hoursms)/(60*1000));
      
           let d=('0'+days).slice(-2)+"d "
           if (days<1) {
           d=""
           }
           if (days>99) {
             d=days+"d "
           }
           let h =('0'+hours).slice(-2)+"h "
           let m =('0'+minutes).slice(-2)+"m"
      
           cameraTime2= d+h+m
      
       }
      


 }, [props.data]);


 function time() {

  let time

  if (data.parkingTimeRestrictionInMinutes>0) {

    function TimeLimit(num) {
      let daysT = Math.floor(num/1440);
      let hoursT = Math.floor((num-(daysT*1440))/60);
      let minutesT = Math.round(num%60);
  
      let dT=('0'+daysT).slice(-2)+"d "
      if (daysT<1) {
      dT=""
      }
      if (daysT>99) {
        dT=daysT+"d "
      }
      let hT =('0'+hoursT).slice(-2)+"h "
      let mT =('0'+minutesT).slice(-2)+"m"
  
      return (
         dT+hT+mT
      )
      
    }
  
    time=TimeLimit(data.parkingTimeRestrictionInMinutes)
  }

  else {
    time=null
  }



  if(time!=null && data.parkingTimeRestrictionEnabled==true) {
  return(
    <div>
<tr>
<td className="td-cam breakwords">{props.t('elementeAllgemein.zeitueberwachung')}</td>
<td className="td-cam">{time}</td>
</tr>
</div>
  )
  }

  else {
    return(
      <div>
<tr>
<td className="td-cam breakwords">{props.t('elementeAllgemein.zeitueberwachung')}</td>
<td className="td-cam">{props.t('allgemein.aus')}</td>
</tr>
</div> )
  }

}



function getModus() {

  let modus
         
    if (data.mode==0) {
      modus=props.t('lot.modes.Automatic')
    }

    else if (data.mode==1) {
      modus=props.t('lot.modes.ManualFree')
   }

   else if (data.mode==2) {
    modus=props.t('lot.modes.ManualOccupied')
   }

   else if (data.mode==3) {
    modus=props.t('lot.modes.ManualBlocked')
   }

   return modus
}

function getStatus() {

  let status

    if (data.occupied==true ) {
      status=props.t('allgemein.besetzt')
      }

    else (
      status=props.t('allgemein.frei')
      )

    if (data.defect==null) {
      status=props.t('allgemein.unbekannt')
    } 


    return status
}


function getCameraStream() {
  return (
    <>
    <img src={cameraLink} width="100%" height="100%" style={{pointerEvents:'none'}}/>
    </>
  )
}

return (
    <div style={{width:'100%',display:'flex',flexDirection:'column'}}> 
   
   {cameraLink!=null &&
   <div style={{width:"100%", height:"400px"}}>
    {getCameraStream()}
    </div>
   }
        
    <tr>
<td className="td-cam">{props.t('allgemein.zustand')}</td>
<td className="td-cam">{getStatus()}<br></br> ({getModus()})</td>
</tr>


<tr>
<td className="td-cam">{props.t('elementeAllgemein.kundengruppe')}</td>
<td className="td-cam">{props.t('elementeAllgemein.kundengruppen.'+data.customerGroup.name)}</td>
</tr>

<tr>
<td className="td-cam">Typ</td>
<td className="td-cam">{props.t('elementeAllgemein.typen.'+data.lotType.name)}</td>
</tr>

<tr>
<td className="td-cam">{props.t('elementeAllgemein.area')}</td>
<td className="td-cam">{data.parentAreaName}</td>
</tr>



{data.occupied==true &&
<>

{data.licensePlate!=null &&
<tr>
<td className="td-cam">{props.t('allgemein.kennzeichen')}</td>
<td className="td-cam">{data.licensePlate}</td>
</tr>
  }

<div className="timetable">
  <tr >
      <td className="td-cam" >{props.t('lot.parkBeginn')}</td>
<td className="td-cam" >{cameraDate}<br></br>{cameraTime}</td>
</tr>
<tr >
<td className="td-cam" >{props.t('lot.parkDauer')}</td>
<td className="td-cam" >{cameraTime2}</td>
</tr>
      </div>
 {time()}
 </>

}



<tr>
<td className="td-cam">{props.t('allgemein.helligkeit')}</td>
<td className="td-cam">{props.t('allgemein.aktuell')}: {data.lightIntensity_Current}%<br></br>{props.t('allgemein.standard')}: {data.lightIntensity_Default}%</td>
</tr>

{data.licensePlate!=null &&
<div style={{paddingLeft:'20px',marginTop:'20px'}} className="flex-col-1">
Nummernschild überschreiben

<div className="flex-iac">
<input type="text" style={{width:'200px',marginRight:'20px'}}/>
<button class="button">{props.t('allgemein.speichern')}</button>
</div>
</div>
  }

   
      </div> 
)
}




export default withNamespaces()(SettingsCamera)