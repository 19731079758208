import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import SelectSingle from "react-select";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import SelectMultiple from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import customStyles from '../../assets/select/customStyles'


const MenuProps = { PaperProps: {style: {maxHeight: 250,marginTop:30,},},getContentAnchorEl: null};

let names=[]
let optionsCustomerGroups=[]

// Dropdown with customer groups - single and multiple option

const SelectCustomerGroups = (props) =>  {

  let jwt = localStorage.getItem('JWT')
  let link = localStorage.getItem('link')
  let customerGroups=JSON.parse(localStorage.getItem('customerGroups'))

  let data=props.data
  let settingsType=props.type

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

  let multipleGroups=[]
  let singleGroup=null

  function getNames() {

    names=[]
    optionsCustomerGroups=[]

    if (settingsType=="Statistics") {
          
      names.push (
        { value: 0, label:  props.t('allgemein.gesamt')}
      )

      optionsCustomerGroups.push (
        { value: 0, label: <div className="flex-iac settings-item">{props.t('allgemein.gesamt')}</div> }
      )
      }

    for (let i =0;i < customerGroups.length; i++) {
      if (customerGroups[i].name.includes(".")) {
        names.push (
          { value: customerGroups[i].id, label: customerGroups[i].name }
        )
  
        optionsCustomerGroups.push (
          { value: customerGroups[i].id, label: <div className="flex-iac settings-item">{customerGroups[i].name}</div> }
        )
      }
      else {

        names.push (
          { value: customerGroups[i].id, label:  props.t('elementeAllgemein.kundengruppen.' + customerGroups[i].name  )}
        )
  
        optionsCustomerGroups.push (
          { value: customerGroups[i].id, label: <div className="flex-iac settings-item">{props.t('elementeAllgemein.kundengruppen.' + customerGroups[i].name )}</div> }
        )
      }
    
    }

  }

  getNames()

  

    function renderMultiple(selectedMultiple) {
      let array=[]

      if (settingsType=="Statistics") {
        if (selectedMultiple.includes(0)) array.push(props.t('allgemein.gesamt'))
      }

      for (let i =0;i < customerGroups.length; i++) {
        if (customerGroups[i].name.includes(".")) { 
          if (selectedMultiple.includes(customerGroups[i].id)) array.push(customerGroups[i].name);
        }
        else {
          if (selectedMultiple.includes(customerGroups[i].id)) array.push(props.t('elementeAllgemein.kundengruppen.'+ customerGroups[i].name));
        }
       
      }
     
      return array.join(', ')
     }
     
     const multipleChange = (event) => {
       setSelectedMultiple(event.target.value)
   };

   const singleChange = (event) => {

   axios.put(link+'/api/'+settingsType+'/CustomerGroup?customerGroupId='+event.value, [data.id], {
       
  })  .then((response) => {  
    for (let i =0; i < optionsCustomerGroups.length ;i++) {
      if (event.value==optionsCustomerGroups[i].value) {
        singleGroup=optionsCustomerGroups[i]
    }
      }       
       setSelectedSingle(singleGroup)
      
  })

  .catch(error => {
    setSelectedSingle(singleGroup)
 })

 };

  const [selectedMultiple, setSelectedMultiple] = useState(multipleGroups);
  const [selectedSingle, setSelectedSingle] = useState();

  const multipleClose = (event) => {

    if (settingsType=="Statistics") {   
      let array=selectedMultiple
      array.sort(function (a, b) {  return a - b;  }); 
      props.onChange(array)
      }

      else {
        axios.put(link+'/api/'+settingsType+'/CustomerGroup?elementId='+data.id, selectedMultiple, {

          headers: { "Content-type": "application/json" },
     
        })  .then((response) => {     
          multipleGroups=selectedMultiple
        })
    
        .catch(error => {
          setSelectedMultiple(multipleGroups)
       })
      }


  
  };
  
  useEffect(() => {

    if (settingsType=="Area") {
      if (data.areaValues.customerGroup!=null || data.areaValues.customerGroup!=undefined) {
        for (let i =0; i < optionsCustomerGroups.length ;i++) {
          if (data.areaValues.customerGroup==optionsCustomerGroups[i].value) {
            singleGroup=optionsCustomerGroups[i]
        }
          }
        }
        setSelectedSingle(singleGroup)
    }

    else if (settingsType=="Lot") {
      if (data.customerGroup!=null || data.customerGroup!=undefined) {
      for (let i =0; i < optionsCustomerGroups.length ;i++) {
        if (data.customerGroup.id==optionsCustomerGroups[i].value) {
          singleGroup=optionsCustomerGroups[i]
      }
        }
      }
        setSelectedSingle(singleGroup)
    }

    else {

      if (settingsType=="Statistics") {
        return
      }


      if (data.customerGroups!=null || data.customerGroups!=undefined) {
        for (let i =0; i < data.customerGroups.length ;i++) {
          for (let j =0; j < optionsCustomerGroups.length ;j++) {
            if (data.customerGroups[i].id==optionsCustomerGroups[j].value) {
              multipleGroups.push(optionsCustomerGroups[j].value)    
            }
          }
          }
      }
          setSelectedMultiple(multipleGroups)
    }

  }, [props.data]);

    function render() {

        if (settingsType=="Area" || settingsType=="Lot") {
            return (
              <SelectSingle className="select" styles={customStyles} isSearchable={false} value={selectedSingle} onChange={singleChange}
              options={optionsCustomerGroups} theme={theme => ({...theme, borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff'}})}/>       
            )
          }
  
          else {
            return (

              <SelectMultiple  className="select" style={{width:'100%'}} multiple value={selectedMultiple} onChange={multipleChange} onClose={multipleClose} input={<Input />} renderValue={renderMultiple} MenuProps={MenuProps} >
        {names.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            <Checkbox  color="primary" size="small" checked={selectedMultiple.indexOf(item.value) > -1} />
            <ListItemText primary={item.label}/>
          </MenuItem>
        ))} </SelectMultiple>
              
            
            )}   
            
    }
  
    return(
       <div >
    {render()}
    </div>
    )
  }



  export default React.memo(withNamespaces()(SelectCustomerGroups))