import React, { useState,useEffect } from 'react';
import {Dropdown,DropdownToggle, DropdownMenu,DropdownItem} from "reactstrap";

import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

import usFlag from "../../assets/images/flags/us.jpg";
import germany from "../../assets/images/flags/germany.jpg";

const LanguageDropdown = (props) => {

  const [menu, setMenu] = useState(false);
  const [flag, setFlag] = useState(germany);
  const [lng, setLng] = useState(localStorage.getItem("i18nextLng"));

  useEffect(() => {
    if(lng === "gr")
  {
       setFlag(germany); 
  }    
 
  else if(lng === "eng")
  {
      setFlag(usFlag); 
  }  
  }, []);


   function changeLanguageAction(lng) {

     i18n.changeLanguage(lng);

    if(lng === "gr")
    {
         setFlag(germany); 
         setLng('German')
    }    
   
    else if(lng === "eng")
    {
        setFlag(usFlag); 
        setLng('English');
    }  

    document.getElementsByTagName("html")[0].setAttribute("lang", localStorage.getItem("i18nextLng")) 

  }


  return (

        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            tag="button"
          >
            <img
              src={flag}
              height="16"
              className="mr-1"
            />
          
          </DropdownToggle>
          <DropdownMenu className="language-switch dropdown-menu" right>
          <DropdownItem className="dropdown-item" onClick={() => changeLanguageAction('gr')} >
              <img src={germany} className="mr-1" height="12" />
              <span >{props.t('allgemein.sprachen.de')}</span>
            </DropdownItem>
            <DropdownItem className="dropdown-item" onClick={() => changeLanguageAction('eng')}>
              <img src={usFlag} className="mr-1" height="12" />
              <span >{props.t('allgemein.sprachen.en')}</span>
            </DropdownItem> 
          </DropdownMenu>
        </Dropdown>
    
  );
}

export default withNamespaces()(LanguageDropdown);