import React from 'react';
import { withNamespaces } from 'react-i18next';

let groupsNum=1
let cgNum=1
let ltNum=1

let hoverAreaContainer

const InfoAreas = (props) =>  {

    let detailedAreaData=props.data
    let hoverAreaId=props.id
    let hoverAreaName

    let groups_1=[]
    let groups_2=[]
    let groups_3=[]

    let types_1=[]
    let types_2=[]
    let types_3=[]

    let nameStr

    for (let i = 0; i < detailedAreaData.length; i++) {

      if (detailedAreaData[i].areaValues != null) {

      if(detailedAreaData[i].id==hoverAreaId && detailedAreaData[i].areaValues.constructed != 0 && detailedAreaData[i].customerGroupAreaValues != null
        && detailedAreaData[i].lotTypeAreaValues != null ) {

        groupsNum=0
        cgNum=0 
        ltNum=0
     
        for (let j = 0; j < detailedAreaData[i].customerGroupAreaValues.length; j++) {
          if (detailedAreaData[i].customerGroupAreaValues[j].constructed != 0) {
          cgNum++

          if (detailedAreaData[i].customerGroupAreaValues[j].customerGroupName.includes(".")) {
            nameStr=detailedAreaData[i].customerGroupAreaValues[j].customerGroupName
          }
          else {
            nameStr=props.t('elementeAllgemein.kundengruppen.'+detailedAreaData[i].customerGroupAreaValues[j].customerGroupName)
          }

          groups_1.push(
            <tr>
          <td className="td2-first" data-column="Kundengruppe">{nameStr} (G)</td>
          <td className="td2" data-column="Proj">{detailedAreaData[i].customerGroupAreaValues[j].constructed}</td>
          <td className="td2" data-column="Frei">{detailedAreaData[i].customerGroupAreaValues[j].available}</td>
          <td className="td2" data-column="Bel.">{detailedAreaData[i].customerGroupAreaValues[j].displayValueForOccupied}</td>
          <td className="td2" data-column="Bel. %">{Math.round(detailedAreaData[i].customerGroupAreaValues[j].displayValueForOccupiedPercent)}%</td>
          </tr>
          )

          groups_2.push(
            <tr>
          <td className="td3-2" >{nameStr} (G)</td>
          </tr>
          )

          groups_3.push(
            <table className="table3">
            <tr>
            <td className="td3-2" >{detailedAreaData[i].customerGroupAreaValues[j].realFree}</td>
            </tr>
            <tr>
            <td className="td3-2" >{detailedAreaData[i].customerGroupAreaValues[j].realOccupied}</td>
            </tr>
            <tr>
            <td className="td3-2" >{detailedAreaData[i].customerGroupAreaValues[j].defect}</td>
            </tr>
            <tr>
            <td className="td3-2" >{detailedAreaData[i].customerGroupAreaValues[j].reserved}</td>
            </tr>
            <tr>
            <td className="td3-2" >{detailedAreaData[i].customerGroupAreaValues[j].manualFree}</td>
            </tr>
            <tr>
            <td className="td3-2" >{detailedAreaData[i].customerGroupAreaValues[j].manualOccupied}</td>
            </tr>
            <tr>
            <td className="td3-2" >{detailedAreaData[i].customerGroupAreaValues[j].buffer}</td>
            </tr>
            <tr>
            <td className="td3-2">{detailedAreaData[i].customerGroupAreaValues[j].hysteresis}</td>
            </tr>
            <tr>
            <td className="td3-2">{detailedAreaData[i].customerGroupAreaValues[j].exceeded}</td>
            </tr>
            <tr>
            <td className="td3-2">{detailedAreaData[i].customerGroupAreaValues[j].preCounted}</td>
            </tr>
            </table>
          )
          }
    }

    for (let x = 0; x < detailedAreaData[i].lotTypeAreaValues.length; x++) {
      if (detailedAreaData[i].lotTypeAreaValues[x].constructed != 0) {
        ltNum++

        if (detailedAreaData[i].lotTypeAreaValues[x].lotTypeName.includes(".")) {
          nameStr=detailedAreaData[i].lotTypeAreaValues[x].lotTypeName
        }
        else {
          nameStr=props.t('elementeAllgemein.typen.'+detailedAreaData[i].lotTypeAreaValues[x].lotTypeName)
        }

      types_1.push(
        <tr>
      <td className="td2-first" data-column="Kundengruppe">{nameStr} (T)</td>
      <td className="td2" data-column="Proj">{detailedAreaData[i].lotTypeAreaValues[x].constructed}</td>
      <td className="td2" data-column="Frei">{detailedAreaData[i].lotTypeAreaValues[x].available}</td>
      <td className="td2" data-column="Bel.">{detailedAreaData[i].lotTypeAreaValues[x].displayValueForOccupied}</td>
      <td className="td2" data-column="Bel. %">{Math.round(detailedAreaData[i].lotTypeAreaValues[x].displayValueForOccupiedPercent)}%</td>
      </tr>
      )

      types_2.push(
        <tr>
      <td className="td3-2">{nameStr} (T)</td>
      </tr>
      )

      types_3.push(
        <table className="table3">
        <tr>
        <td className="td3-2" >{detailedAreaData[i].lotTypeAreaValues[x].realFree}</td>
        </tr>
        <tr>
        <td className="td3-2" >{detailedAreaData[i].lotTypeAreaValues[x].realOccupied}</td>
        </tr>
        <tr>
            <td className="td3-2" >{detailedAreaData[i].lotTypeAreaValues[x].defect}</td>
            </tr>
        <tr>
        <td className="td3-2" >{detailedAreaData[i].lotTypeAreaValues[x].reserved}</td>
        </tr>
        <tr>
        <td className="td3-2" >{detailedAreaData[i].lotTypeAreaValues[x].manualFree}</td>
        </tr>
        <tr>
        <td className="td3-2" >{detailedAreaData[i].lotTypeAreaValues[x].manualOccupied}</td>
        </tr>
        <tr>
        <td className="td3-2" >{detailedAreaData[i].lotTypeAreaValues[x].buffer}</td>
        </tr>
        <tr>
        <td className="td3-2">{detailedAreaData[i].lotTypeAreaValues[x].hysteresis}</td>
        </tr>
        <tr>
        <td className="td3-2">{detailedAreaData[i].lotTypeAreaValues[x].exceeded}</td>
        </tr>
        <tr>
        <td className="td3-2">{detailedAreaData[i].lotTypeAreaValues[x].preCounted}</td>
        </tr>
        </table>
      )
      }
}


hoverAreaName=detailedAreaData[i].name
groupsNum=cgNum + ltNum
hoverAreaContainer = {top:props.hoverstyle.top,left:props.hoverstyle.left, width:240+50*groupsNum};

  }

  else if(detailedAreaData[i].id==hoverAreaId && detailedAreaData[i].areaValues.constructed == 0) {
    hoverAreaContainer = null
  }

} else if(detailedAreaData[i].id==hoverAreaId) {
  hoverAreaContainer = null
}

}


    function addGroupsTypes1() {
      return (
        <tbody>
       {types_1}
       {groups_1}
      </tbody>
   
      )
    }

    function addGroupsTypes2() {
      return(
        <table className="table3-container">
        <tr>
        <td className="td3">{props.t('elementeAllgemein.typ')}/{props.t('elementeAllgemein.kundengruppe')}</td>
        </tr>
        {types_2}
        {groups_2}
        </table>
      ) 
    }

    function addGroupsTypes3(){
      return (
        <div className="table3-container">
          <table className="table3">
          <tr>
          <td className="td3" >{props.t('elementeAllgemein.areaWerte.RealFree')}</td>
          </tr>
          <tr>
          <td className="td3" >{props.t('elementeAllgemein.areaWerte.RealOccupied')}</td>
          </tr>
          <tr>
          <td className="td3" >{props.t('elementeAllgemein.areaWerte.Defect')}</td>
          </tr>
          <tr>
          <td className="td3" >{props.t('elementeAllgemein.areaWerte.Reserved')}</td>
          </tr>
          <tr>
          <td className="td3" >{props.t('elementeAllgemein.areaWerte.Free_kurz')} ( {props.t('allgemein.manuell')} )</td>
          </tr>
          <tr>
          <td className="td3" >{props.t('elementeAllgemein.areaWerte.Occupied_kurz')} ( {props.t('allgemein.manuell')} )</td>
          </tr>
          <tr>
          <td className="td3" >{props.t('elementeAllgemein.puffer')}</td>
          </tr>
          <tr>
          <td className="td3">{props.t('elementeAllgemein.hysterese')}</td>
          </tr>
          <tr>
          <td className="td3">{props.t('elementeAllgemein.areaWerte.Exceeded')}</td>
          </tr>
          <tr>
          <td className="td3">{props.t('elementeAllgemein.areaWerte.Precount')}</td>
          </tr>
          </table>
          {types_3}
        {groups_3}
        </div>
      )
    }

    return(
       <div id="showinfo" style={hoverAreaContainer}>
         <div className="flexcontainer">
      <div className="tablehovername">{hoverAreaName}</div>
      <div className="tablehoverinfo">{props.t('allgemein.anzeigewerte')}</div>
  <div className="table2-container">
  <table className="table2">
        <thead className="thead2">
          <tr>
          <th className="th2-first th2">{props.t('elementeAllgemein.typ')}/{props.t('elementeAllgemein.kundengruppe')}</th>
            <th className="th2">{props.t('elementeAllgemein.areaWerte.Constructed_kurz')}</th>
            <th className="th2">{props.t('elementeAllgemein.areaWerte.Free_kurz')}</th>
            <th className="th2">{props.t('elementeAllgemein.areaWerte.Occupied_kurz')}</th>
            <th className="th2">{props.t('elementeAllgemein.areaWerte.Occupied_kurz')} %</th>
          </tr>
        </thead>
        {addGroupsTypes1()}
      </table> 
  </div>
  <div className="tablehoverinfo bordertable">{props.t('allgemein.detailwerte')}</div>
  {addGroupsTypes2()}
  {addGroupsTypes3()}
  </div>
    </div>
    )
}



  export default withNamespaces()(InfoAreas)