import React, {useState, useEffect, useRef} from 'react';
import SimpleBar from "simplebar-react";
import { withNamespaces } from 'react-i18next';
import axios from "axios";

import {Alert} from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

import NumericInput from 'react-numeric-input';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import customStyles from '../../../assets/select/customStyles'
import Select from "react-select";

import usFlag from "../../../assets/images/flags/us.jpg";
import gerFlag from "../../../assets/images/flags/germany.jpg";

import Checkbox from '@material-ui/core/Checkbox';

import TreeArea from "../../CommonComponents/TreeArea.js";
import TreeAreaMain from "../../CommonComponents/TreeAreaMain.js";

  const showSecond = false;
  const str = showSecond ? 'hh : mm : ss' : 'hh : mm';
  
  function onChange(value) {
    console.log(value && value.format(str));
  }



const PopUp = (props) =>  {

  let link = localStorage.getItem('link')
  let data=props.data

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [email, setEmail] = useState();
  const [rights, setRights] = useState({ value: 'other', label:   <div className="flex-iac settings-item">{props.t('administration.benutzertypen.andere')}</div> });
  const [canChange, setCanChange] = useState({ value: 'no', label:   <div className="flex-iac settings-item">{props.t('allgemein.nein')}</div> });
  const [failShow, setFailShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [carParkId, setCarParkId]= useState()
  const [elementType, setElementType] = useState({ value: 'Lot', label: props.t('lot.lot') })
  const [titel, setTitel] = useState();
  const [category, setCategory] = useState();
  const [externalName, setExternalName] = useState();

  const optionsRights = [
    { value: 'admin', label:  <div className="flex-iac settings-item">{props.t('administration.benutzertypen.admin')}</div> },
    { value: 'other', label:   <div className="flex-iac settings-item">{props.t('administration.benutzertypen.andere')}</div> },
  ];

  const optionsCanChange = [
    { value: 'yes', label:  <div className="flex-iac settings-item">{props.t('allgemein.ja')}</div> },
    { value: 'no', label:   <div className="flex-iac settings-item">{props.t('allgemein.nein')}</div> },
  ];

  const optionsType = [
    { value: 'Area', label: props.t('elementeAllgemein.area') },
    { value: 'NumericalDisplay', label: props.t('numDisplay.numDisplay')},
    { value: 'Lot', label: props.t('lot.lot') },
    { value: 'TextDisplay', label: props.t('textDisplay.textDisplay')},
    { value: 'ZoneDisplay', label: props.t('zoneDisplay.zoneDisplay')},
  ];

  // const [activated, setActivated] = useState();
  // const [language, setLanguage] = useState({ value: 'de', label: <div className="flex-iac settings-item"><img src={gerFlag} className="select-icon" style={{width:'22px'}}/> {props.t('allgemein.sprachen.de')}</div> });

  // const optionsLanguages = [
  //   { value: 'de', label: <div className="flex-iac settings-item"><img src={gerFlag} className="select-icon" style={{width:'22px'}}/> {props.t('allgemein.sprachen.de')}</div> },
  //   { value: 'en', label: <div className="flex-iac settings-item"><img src={usFlag} className="select-icon" style={{width:'22px'}}/> {props.t('allgemein.sprachen.en')}</div> },
  // ];

  // const optionsRights = [
  //   { value: 'keyUser', label:  <div className="flex-iac settings-item">{props.t('administration.benutzertypen.hauptnutzer')}</div> },
  //   { value: 'user', label:   <div className="flex-iac settings-item">{props.t('administration.benutzertypen.nutzer')}</div> },
  //   { value: 'guest', label:   <div className="flex-iac settings-item">{props.t('administration.benutzertypen.gast')}</div> },
  // ];

    // function languageChange(value) {
  //   setLanguage(value);
  // }

  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
  }, [localStorage.getItem('JWT')]);


  useEffect(() => {

    if (props.type=="createEditDeleteUser") {

      let userName=""
         let eMail=""
         let isMultiGuideAdmin=null 
         let canChange=null 

         if (data!=null) {
          data.isMultiGuideAdmin==true ? isMultiGuideAdmin=true : isMultiGuideAdmin=null 
          data.canChange==true ? canChange=true : canChange=null 
          data.userName!=null ? userName=data.userName  : userName="" 
          data.eMail!=null ? eMail=data.eMail  : eMail=""
         }

        setUsername(userName)
        setEmail(eMail)
        isMultiGuideAdmin==true ? setRights({ value: 'admin', label:  <div className="flex-iac settings-item">{props.t('administration.benutzertypen.admin')}</div> }) 
        : setRights({ value: 'other', label:   <div className="flex-iac settings-item">{props.t('administration.benutzertypen.andere')}</div> })
        canChange==true ? setCanChange({ value: 'yes', label:  <div className="flex-iac settings-item">{props.t('allgemein.ja')}</div> }) 
        : setCanChange({ value: 'no', label:   <div className="flex-iac settings-item">{props.t('allgemein.nein')}</div> })
    }
 
    else if (props.type=="createEditDeleteTag") {

      let titel=""
      let category=""
      let externalName=""
      let elementType=""
      let carParkId=0

      function getElementType(type) {
        for (let i=0; i < optionsType.length;i++) {
          if (type==optionsType[i].value) {
            return optionsType[i]
          }
        }
      }

      if (data!=null) {
       data.title!=null ? titel=data.title : titel=""
       data.category!=null ? category=data.category  : category=""
       data.externalName!=null ? externalName=data.externalName : externalName=""
       data.elementType!=null ? elementType=getElementType(data.elementType)  : elementType=""
       data.carParkId!=null ? carParkId=data.carParkId : carParkId=0
      }

      setTitel(titel)
      setCategory(category)
      setExternalName(externalName)
      setElementType(elementType)
      setCarParkId(carParkId)
    
  }

  }, [props]);

  let selectedType=props.type


  function onElementTypeChange(value) {
    setElementType(value);
  }

  function onTitelChange(value) {
    setTitel(value);
  }

  function onCategoryChange(value) {
    setCategory(value);
  }

  function onExternalNameChange(value) {
    setExternalName(value);
  }

  function onRightsChange(value) {
    setRights(value);
  }

  function onCanChange(value) {
    setCanChange(value);
  }

  function usernameChange(props) {
    setUsername(props) 
    setFailShow(false)
}

function passwordChange(props) {
    setPassword(props) 
    setFailShow(false)
}

function passwordChangeConfirm(props) {
  setPasswordConfirm(props) 
  setFailShow(false)
}

function emailChange(props) {
  setEmail(props) 
  setFailShow(false)
}


    function weekDays() {
      return (
        <div className="flex-iac">
        <div className="flex-iac">
          {props.t('allgemein.tageKurz.Monday')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        <div className="flex-iac">
        {props.t('allgemein.tageKurz.Tuesday')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        <div className="flex-iac">
        {props.t('allgemein.tageKurz.Wednesday')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        <div className="flex-iac">
        {props.t('allgemein.tageKurz.Thursday')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        <div className="flex-iac">
        {props.t('allgemein.tageKurz.Friday')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        <div className="flex-iac">
        {props.t('allgemein.tageKurz.Saturday')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        <div className="flex-iac">
        {props.t('allgemein.tageKurz.Sunday')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        </div>
      )
    }

    function carPopUp() {
      return (
        <div style={{width:'90%',margin:'auto'}}>
        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('allgemein.bereich')}</div> 
        <div className="content-areas-se" style={{height:'200px'}}>
           <SimpleBar style={{height:'200px'}}>
            <TreeArea multi={false} page={"settings-1"} type={"loopMovements"}></TreeArea>
           </SimpleBar>
            </div>
        </div> 

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('occupancy.einstellung_helligkeitstimer.wochentage')}</div>
        {weekDays()}
        </div> 

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('allgemein.Uhrzeit')}</div> 
        <TimePicker showSecond={showSecond} className="time-input input-we2" onChange={onChange} placeholder={str}/>
        </div>

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('administration.countingAreas.wert')}</div> 
        <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100} />
        </div>

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('allgemein.aktiv')}</div> 
        <Checkbox  color="primary" size="small" style={{padding:'0px'}}/>
       </div>
      
        <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'10px'}}>
        <button className="button" >{props.t('allgemein.speichern')}</button> 
        </div>
        </div>
      )
    }

    
    function litPopUp() {
      return (
        <div style={{width:'90%',margin:'auto'}}>
        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('allgemein.bereich')}</div> 
        <div className="content-areas-se" style={{height:'200px'}}>
           <SimpleBar style={{height:'200px'}}>
            <TreeArea multi={false}  page={"settings-2"}></TreeArea>
           </SimpleBar>
            </div>
        </div> 

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('occupancy.einstellung_helligkeitstimer.wochentage')}</div>
        {weekDays()}
        </div> 

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('allgemein.zeitraum')}</div> 
         
        <TimePicker showSecond={showSecond} className="time-input input-we2" onChange={onChange} placeholder={str}/>
        &nbsp;-&nbsp;
        <TimePicker showSecond={showSecond} className="time-input input-we2" onChange={onChange} placeholder={str}/>

        </div>

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('elementeAllgemein.helligkeitInProzent')}</div>    
        <NumericInput className="input-numeric input100" mobile={"auto"} min={0} max={100}/>
        </div>

        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('occupancy.einstellung_helligkeitstimer.gueltigFuer')}</div> 

        <div className="flex-iac">
         <div>
        {props.t('occupancy.einstellung_helligkeitstimer.gueltigFuerSensoren')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}}/>
        </div>

        <div style={{marginLeft:'5px'}}>
        {props.t('occupancy.einstellung_helligkeitstimer.gueltigFuerSchilder')}
        <Checkbox  color="primary" size="small"  style={{padding:'5px'}} />
        </div>
        </div>

       </div>
      
        <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'10px'}}>
        <button className="button" >{props.t('allgemein.speichern')}</button> 
        </div>
        </div>
      )
    }

    function ceduPopUp() {

      function createUser() {

        if (password!=passwordConfirm) {
          setErrorMessage(props.t('administration.benutzer.fehlerPasswoerterStimmenNicht'))
          setFailShow(true)
          return
        }

        let admin=false
        let change=false

        rights.value=="admin" ? admin=true : admin=false
        canChange.value=="yes" || rights.value=="admin" ? change=true : change=false

        if (password==passwordConfirm) {

          const data = {
            userName:username,
            password:password,
            eMail:email,
            isMultiGuideAdmin: admin,
            canChange: change
        }
  
  
  
             axios.post(link+'/api/User/update',data, {
             headers: {
            'Content-Type': 'application/json',
        }
             
                })  .then((response) => {  
               
                  props.onChange()

                })
              
                .catch(error => {
                   if (error.response.status=="500")(
                     setErrorMessage(props.t('login.fehlerPasswortAenderung_2'))          
                    )
                    else {
                      setErrorMessage(props.t('allgemein.fehlgeschlagen'))   
                    }
                    setFailShow(true)
               
               })

        }

  
    }

    function  handleValidSubmit() {
      createUser()
  }



      return (
        <AvForm className="form-horizontal" onValidSubmit={() => { handleValidSubmit() }} style={{opacity:'1'}}>

{props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

        <div style={{width:'90%',margin:'auto'}}>
        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('allgemein.anmeldungsdaten')}</div> 

        <div className="form-group">
         <AvField style={{height:'25px'}} name="username" autoComplete="new-username" label={props.t('login.labelBenutzername')+ " :"} value={username} className="form-control" type="username" required 
          onChange={(e)=>usernameChange(e.target.value)}/>
         </div>

         <div className="form-group">
         <AvField style={{height:'25px'}} name="email" autoComplete="new-email" label={props.t('administration.benutzer.labelEMail')+ " :"} value={email} className="form-control"  type="email" required 
         onChange={(e)=>emailChange(e.target.value)}/>
         </div>

        <div className="form-group">
          <AvField style={{height:'25px'}} name="password" autoComplete="new-password" label={props.t('login.labelPasswort')+ " :"} value={password} type="password" required 
           onChange={(e)=>passwordChange(e.target.value)}/>
            </div>

           <div className="form-group">
            <AvField style={{height:'25px'}} name="passwordConfirm" autoComplete="new-password" label={props.t('administration.benutzer.labelPasswortBestaetigung')+ " :"} value={passwordConfirm} type="password" required 
            onChange={(e)=>passwordChangeConfirm(e.target.value)}/>
             </div>    

        {/* <div style={{marginTop:'10px'}}>   
        <div className="content-group-name">{props.t('administration.benutzer.labelSprache')}</div> 
        <div style={{width:'100%',marginTop:'10px'}}>
        <Select closeMenuOnSelect={true} styles={customStyles} isSearchable={false} isMulti={false}  onChange={languageChange}
       options={optionsLanguages} value={language} theme={theme => ({...theme,borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff',}, })} />
      </div>
      </div> */}

      <div style={{marginTop:'15px'}}>   
        <div className="content-group-name">{props.t('administration.benutzer.labelRolle')}</div> 
        <div style={{width:'100%',marginTop:'10px'}}>
        <Select closeMenuOnSelect={true} styles={customStyles} isSearchable={false} isMulti={false}  onChange={onRightsChange}
       options={optionsRights} value={rights} theme={theme => ({...theme,borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff',}, })} />
      </div>
      </div>

      {rights.value!="admin" && (
       <div style={{marginTop:'10px'}}>   
       <div className="content-group-name">{props.t('administration.benutzer.labelAenderung')}</div> 
       <div style={{width:'100%',marginTop:'10px'}}>
       <Select closeMenuOnSelect={true} styles={customStyles} isSearchable={false} isMulti={false}  onChange={onCanChange}
      options={optionsCanChange} value={canChange} theme={theme => ({...theme,borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff',}, })} />
     </div>
     </div>
         )}

 
      <div style={{display:'flex',justifyContent:'flex-end',marginTop:'10px',marginBottom:'10px'}}>
        <button type="submit" className="button">{props.t('allgemein.speichern')}</button> 
        </div>

        {failShow==true && (
      <div className="login-fail">{errorMessage}</div>
      )}

        </div> 


        {/* <div style={{marginTop:'20px'}}>
        <div className="content-group-name">{props.t('administration.benutzer.labelBeschreibung')}</div> 
        <textarea style={{resize:'none',height:'70px',width:'100%'}}></textarea>
        </div>
      
        <div style={{marginTop:'20px'}}>
        <div className="content-group-name">Areabeschränkung</div> 
        <textarea style={{resize:'none',height:'70px',width:'100%'}}></textarea>
        </div> */}
           
        </div>
        </AvForm>
      )
    }

    function tagPopUp() {

      function createTag() {

          const data = {
             title:titel,
             category:category,
             externalName:externalName,
             elementType: elementType.value,
             carParkId: carParkId
        }
  
             axios.post(link+'/api/Tag',data, {
             headers: {
            'Content-Type': 'application/json',
        }
             
                })  .then((response) => {  
               
                  props.onChange()

                })
              
                .catch(error => {
        
                    setFailShow(true)
               
               })

  
    }

    function updateTag() {

      const data = {
         id:props.data.id,
         title:titel,
         category:category,
         externalName:externalName,
         elementType: elementType.value,
         carParkId: carParkId
    }

         axios.put(link+'/api/Tag',data, {
         headers: {
        'Content-Type': 'application/json',
    }
         
            })  .then((response) => {  
           
              props.onChange()

            })
          
            .catch(error => {
    
                setFailShow(true)
           
           })


}

    function  handleValidSubmit() {
      if (data!=null) {
        updateTag()
      }
      else {
        createTag()
      }   
  }

  function handleTreeAreaMain(props) {
    setCarParkId(props.carParkId)
  }

      return (
        <AvForm className="form-horizontal" onValidSubmit={() => { handleValidSubmit() }} style={{opacity:'1'}}>

{props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

        <div style={{width:'90%',margin:'auto'}}>
        <div style={{marginTop:'20px'}}>

        <div className="form-group">
         <AvField style={{height:'25px'}} name="titel" label={props.t('allgemein.titel')+ " :"} value={titel} className="form-control" type="text" required 
          onChange={(e)=>onTitelChange(e.target.value)}/>
         </div>

         <div className="form-group">
         <AvField style={{height:'25px'}} name="category"  label={props.t('allgemein.kategorie')+ " :"} value={category} className="form-control" type="text" 
          onChange={(e)=>onCategoryChange(e.target.value)}/>
         </div>

         <div className="form-group">
         <AvField style={{height:'25px'}} name="externalName"  label={props.t('allgemein.externerName')+ " :"} value={externalName} className="form-control" type="text" 
          onChange={(e)=>onExternalNameChange(e.target.value)}/>
         </div>

  

      <div style={{marginTop:'15px'}}>   
        <div className="content-group-name">{props.t('kalender.elementTyp')}</div> 
        <div style={{width:'100%',marginTop:'10px'}}>
        <Select closeMenuOnSelect={true} styles={customStyles} isSearchable={false} isMulti={false} onChange={onElementTypeChange}
      options={optionsType} value={elementType} theme={theme => ({...theme,borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff',}, })} />
      </div>
      </div>

      <div style={{marginTop:'15px'}}>   
        <div className="content-group-name">{props.t('elementeAllgemein.parkhaus')}</div> 
        <div className="content-areas-se" style={{height:'250px'}}>  
    <SimpleBar style={{height:'250px'}}>
     <TreeAreaMain data={carParkId} onChange={(props)=> handleTreeAreaMain(props)} ></TreeAreaMain>
     </SimpleBar>
     </div>
      </div>

 
      <div style={{display:'flex',justifyContent:'flex-end',marginTop:'10px',marginBottom:'10px'}}>
        <button type="submit" className="button">{props.t('allgemein.speichern')}</button> 
        </div>

        {failShow==true && (
      <div className="login-fail">{errorMessage}</div>
      )}

        </div> 

        </div>
        </AvForm>
      )
    }



    function renderOption() {
      if (selectedType=="countingAreaReset") {
        return (
          carPopUp() 
         )
      }
      else if (selectedType=="lightIntensityTimer") {
        return (
          litPopUp()
         )
      }
      else if (selectedType=="createEditDeleteUser") {
        return (
          ceduPopUp()
         )
    }
    else if (selectedType=="createEditDeleteTag") {
      return (
        tagPopUp()
       )
  }
  }
    

    return (
      <div id="pop-up-settings">
        <SimpleBar style={{height:'100%'}}>
       {renderOption()}
       </SimpleBar>
    </div>

    )
  }


  
  export default withNamespaces()(PopUp)