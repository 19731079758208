import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";
import SimpleBar from "simplebar-react";
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend } from 'recharts';

import ReactLoading from 'react-loading';

import React, { useState, useEffect, useRef } from 'react';
import infoIcon from '../../assets/images/mgsuite/settings-icon.png';

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import moment from "moment";

let carParkGroups = []
let mainParkingLots = []
let subParkingLots = []
let areaParkingLots = []

let sites = []

let infoOpen

let pageLoaded = false

// let colorArr=[]

let colorArr = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#3B3EAC",
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#3B3EAC"
]

const Dashboard = (props) => {

  let jwt = localStorage.getItem('JWT')
  let link = process.env.REACT_APP_API_URL;

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

  const [loaderShow, setLoaderShow] = useState(false);
  const infoContainer = useRef(null);

  const [selected, setSelected] = useState(['Mode']);
  const [data, setData] = useState([]);
  const [exceeded, setExceeded] = useState([]);
  const [userLog, setUserLog] = useState([]);

  const [customerGroup, setCustomerGroup] = useState([]);
  const [lotType, setLotType] = useState([]);
  const [mode, setMode] = useState([]);
  const [parkingTimeRestrictionEnabled, setParkingTimeRestrictionEnabled] = useState([]);
  const [remark, setRemark] = useState([]);
  const [reserved, setReserved] = useState([]);

  const [makeUpdate, setMakeUpdate] = useState(false);

  // List for special settings select element

  const names = [
    { value: 'Mode', label: props.t('allgemein.modus'), },
    { value: 'Reserved', label: props.t('lot.reserviert') },
    { value: "CustomerGroup", label: props.t('elementeAllgemein.kundengruppe') },
    { value: "LotType", label: props.t('elementeAllgemein.typ') },
    { value: "ParkingTimeRestrictionEnabled", label: props.t('elementeAllgemein.zeitueberwachung') },
    { value: "Remark", label: props.t('allgemein.bemerkung') }
  ];

  const MenuProps = { PaperProps: { style: { maxHeight: 250, marginTop: 30, }, }, getContentAnchorEl: null };

  const handleChange = (event) => {
    setSelected(event.target.value)
  };

  // Function to handle all the translations for the select element

  function renderValue1(selected) {
    let array = []
    if (selected.includes("Mode")) array.push(props.t('allgemein.modus'));
    if (selected.includes("Reserved")) array.push(props.t('lot.reserviert'));
    if (selected.includes("CustomerGroup")) array.push(props.t('elementeAllgemein.kundengruppe'));
    if (selected.includes("LotType")) array.push(props.t('elementeAllgemein.typ'));
    if (selected.includes("ParkingTimeRestrictionEnabled")) array.push(props.t('elementeAllgemein.zeitueberwachung'));
    if (selected.includes("Remark")) array.push(props.t('allgemein.bemerkung'));
    return array.join(', ')
  }

  const [tablet, setTablet] = useState(false);

  $(document).on('click', function (e) {

    if (infoOpen == true && infoContainer.current && !infoContainer.current.contains(e.target) && e.target.id != 'info-icon'
      && ($(e.target)[0] !== $('body')[0]) && ($(e.target).parents('.MuiPaper-root').length !== 1) && ($(e.target).parents('.MuiPopover-root').length !== 1) && !$(e.target).attr("aria-hidden")) {
      $(".right-container-se").hide()
      infoOpen = false
    }

  })

  // Generate random color for the dashboard graph

  function generateRandomColor() {
    let maxVal = 0xFFFFFF;
    let randomNumber = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    randomNumber = randomNumber.toString(16);
    let randColor = randomNumber.padStart(6, 0);
    return `#${randColor.toUpperCase()}`
  }

  // Update data for the dashboard and the list every 20 seconds

  function updateData(data) {

    let formattedData = []

    for (var prop in data) {

      let json = {}
      let data2 = data[prop];

      json["time"] = moment(prop).locale('de').format('HH:mm')

      for (var prop2 in data2) {

        let value = data2[prop2];
        let id = prop2.toString()

        json[id] = value
      }

      formattedData.push(
        json
      )

    }
    setData(formattedData)

  }

  useEffect(() => {

    let interval

    if (window.innerWidth < 1367 && window.innerWidth > 767) {
      setTablet(true)
    }

    if (pageLoaded) {
      setLoaderShow(false)
    }
    else {
      setLoaderShow(true)
    }


    function getAreaData() {

      axios.get(link + '/api/Area/sites')
        .then((response) => {

          sites = []
          sites = response.data

          setMakeUpdate(current => !current)

        })

      axios.get(link + '/api/Statistic/AreaValuesDashboard')
        .then((response) => {

          updateData(response.data)

        })

      axios.get(link + '/api/Lot/Exceeded')
        .then((response) => {

          setExceeded(response.data)

        })

      axios.get(link + '/api/UserActivityLog/')
        .then((response) => {

          setUserLog(response.data)

        })

      axios.get(link + '/api/Lot/SpecialSettings')
        .then((response) => {

          setCustomerGroup(response.data.CustomerGroup.sort((a, b) => a.name - b.name))
          setLotType(response.data.LotType.sort((a, b) => a.name - b.name))
          setMode(response.data.Mode.sort((a, b) => a.name - b.name))
          setParkingTimeRestrictionEnabled(response.data.ParkingTimeRestrictionEnabled.sort((a, b) => a.name - b.name))
          setRemark(response.data.Remark.sort((a, b) => a.name - b.name))
          setReserved(response.data.Reserved.sort((a, b) => a.name - b.name))

        })




      axios.get(link + '/api/Area', {

      }).then((response) => {

        carParkGroups = []
        mainParkingLots = []
        subParkingLots = []
        areaParkingLots = []

        for (let i = 0; i < response.data.length; i++) {
          let cur = response.data[i];

          if (cur.areaType == 1) {  // CarParkGroup
            carParkGroups[cur.id] = cur;
            console.debug("Added CarParkGroup %o", cur);
          }

          if (response.data[i].areaType == 2) {  // CarPark
            mainParkingLots.push(response.data[i])
          }

          if (response.data[i].areaType == 3) {  // Level
            subParkingLots.push(response.data[i])
          }

          if (response.data[i].areaType == 4 || response.data[i].areaType == 5) {   // Area / CountArea
            areaParkingLots.push(response.data[i])
          }

        }

        for (let i = 0; i < areaParkingLots.length; i++) {

          for (let j = 0; j < mainParkingLots.length; j++) {

            if (areaParkingLots[i].parentAreaId === mainParkingLots[j].id) {
              subParkingLots.push(areaParkingLots[i])
            }

          }

        }

        for (let i = 0; i < mainParkingLots.length; i++) {
          //add colors
          mainParkingLots[i]["graphColor"] = colorArr[i]
        }


        mainParkingLots.sort((a, b) => a.areaTreeSortOrder - b.areaTreeSortOrder);

        pageLoaded = true
        setLoaderShow(false)


      })


    }


    getAreaData()

    interval = setInterval(getAreaData, 20000);

    return () => {
      clearInterval(interval)
    }


  }, []);



  function dashboard() {

    function thead() {
      return (
        <thead>
          <tr >
            <th className="th-first"> </th>
            <th >{props.t('elementeAllgemein.areaWerte.Free_kurz')}</th>
            <th >{props.t('elementeAllgemein.areaWerte.Constructed_kurz')}</th>
            <th style={{ color: 'red' }} >{props.t('elementeAllgemein.areaWerte.Defect_kurz')}</th>
            <th >{props.t('elementeAllgemein.areaWerte.Reserved_kurz')}</th>
            <th >{props.t('elementeAllgemein.areaWerte.Exceeded_kurz')}</th>
            <th >{props.t('elementeAllgemein.areaWerte.RealOccupied_kurz')} ( Real )</th>

          </tr>
        </thead>
      )
    }

    // Render a main box with data for all main areas

    function boxMain(site) {

      let rows = []

      if (mainParkingLots.length == 1) {

        for (let i = 0; i < subParkingLots.length; i++) {

          let percentageStyle = {
            background: 'linear-gradient(to right, lightcoral ' + Math.round(subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.realOccupiedPercent : 0) + '%,lightgreen 1%'
          };

          let tr = <tr className="padding">
            <td className="td-first">{subParkingLots[i].name}</td>
            <td>{subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.available : 0}</td>
            <td>{subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.constructed : 0}</td>
            <td style={{ color: 'red' }}>{subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.defect : 0}</td>
            <td>{subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.reserved : 0}</td>
            <td>{subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.exceeded : 0}</td>
            <td style={percentageStyle}>
              <span style={{ marginRight: '40px' }}>{subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.realOccupied : 0}</span>
              <span style={{ position: 'absolute', right: '10px' }}>{Math.round(subParkingLots[i].areaValues != null ? subParkingLots[i].areaValues.realOccupiedPercent : 0)} %</span>
            </td>
          </tr>

          rows.push(tr)



        }

      }

      else {
        for (let i = 0; i < mainParkingLots.length; i++) {

          let correctSite = false

          for (let j = 0; j < site.carParks.length; j++) {
            if (site.carParks[j].id == mainParkingLots[i].id) {
              correctSite = true
            }

          }

          if (correctSite == true) {
            let percentageStyle = {
              background: 'linear-gradient(to right, lightcoral ' + Math.round(mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.realOccupiedPercent : 0) + '%,lightgreen 1%'
            };

            let tr = <tr className="padding">
              <td className="td-first">{mainParkingLots[i].name}</td>
              <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.available : 0}</td>
              <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.constructed : 0}</td>
              <td style={{ color: 'red' }}>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.defect : 0}</td>
              <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.reserved : 0}</td>
              <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.exceeded : 0}</td>
              <td style={percentageStyle}>
                <span style={{ marginRight: '40px' }}>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.realOccupied : 0}</span>
                <span style={{ position: 'absolute', right: '10px' }}>{Math.round(mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.realOccupiedPercent : 0)} %</span>
              </td>
            </tr>

            rows.push(tr)
          }


        }
      }

      function firstRow() {
        let groupId = site.id;
        let groupValues = carParkGroups[groupId];
        console.log("GroupValues from GroupId %o: %o  (site: %o)", groupId, groupValues, site);

        if (groupValues !== undefined){
          let av = groupValues.areaValues;
          return(
            <tr>
            <td className="td-first">{groupValues.name}</td>
            <td>{av != null ? av.available : 0}</td>
            <td>{av != null ? av.constructed : 0}</td>
            <td style={{ color: 'red' }}>{av != null ? av.defect : 0}</td>
            <td>{av != null ? av.reserved : 0}</td>
            <td>{av != null ? av.exceeded : 0}</td>
            <td style={{ background: 'linear-gradient(to right, lightcoral ' + Math.round(av != null ? av.realOccupiedPercent : 0) + '%,lightgreen 1%' }}>
              <span style={{ marginRight: '40px' }}>{av != null ? av.realOccupied : 0}</span>
              <span style={{ position: 'absolute', right: '10px' }}>{Math.round(av != null ? av.realOccupiedPercent : 0)} %</span>
            </td>
          </tr>
          )
        }
      }


      return (
        <div className="box-dashboard">
          <div className="box-name">{site.name}</div>
          <SimpleBar style={{ height: 'calc( 100% - 20px )' }}>
            <div style={{ padding: '5px 5px 2px 5px' }}>
              <table>
                {thead()}
                <tbody>
                  {firstRow()}
                  <div style={{ width: '100%', height: '1px', backgroundColor: '#dadadb', position: 'absolute' }}></div>
                  {rows}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </div>
      )
    }


    // Render a chart for the areas from each site

    function chart(site) {

      let interval = setInterval(updateText, 1);

      function updateText() {
        if ($('#chartContainerDashboard').find(".recharts-wrapper").length > 0) {
          let name = props.t('dashboard.headerBelegungHeute')
          $('.responsive-container').find('#chartNameDashboard').remove();
          $('.responsive-container').find('.recharts-wrapper').append('<div id="chartNameDashboard">' + name + '</div>')
          $('.responsive-container').find('#chartNameDashboard').addClass('chart-name')
          clearInterval(interval)
        }
      }


      const CustomTooltip = ({ active, payload, label }) => {

        if (payload && active && payload.length) {

          return (
            <div className="recharts-tooltip">
              <p style={{ margin: '0px', textAlign: 'center' }}>{`${label}`}</p>
              <ul style={{ padding: '0px', margin: '0px' }}>
                {payload.map(item =>
                  <li className="recharts-item" style={{ color: item.color, textAlign: 'left' }}>{item.name} : {item.value}</li>
                )}
              </ul>

            </div>
          );
        }

        else {
          return null
        }

      }

      return (
        <ResponsiveContainer id="chartContainerDashboard" className="responsive-container">
          <AreaChart data={data} margin={{ top: 35, right: 0, left: 0, bottom: 15 }}>
            <defs>
              {mainParkingLots.map((area) => {
                let correctSite = false

                for (let j = 0; j < site.carParks.length; j++) {
                  if (site.carParks[j].id == area.id) {
                    correctSite = true
                  }

                }

                if (correctSite) {
                  return (
                    <linearGradient id={area.id} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopOpacity={0.8} stopColor={area.graphColor} />
                      <stop offset="95%" stopOpacity={0} stopColor={area.graphColor} />
                    </linearGradient>
                  )
                }


              }
              )

              }


            </defs>
            <XAxis tick={{ fontSize: 9 }} dataKey="time" angle={-45} textAnchor="end" label={{ value: props.t('statistics.zeit'), angle: 0, offset: -15, position: "insideBottom", fontSize: 12 }} />
            <YAxis tick={{ fontSize: 9 }} label={{ value: props.t('statistics.stellplaetze'), angle: -90, offset: 15, position: 'insideLeft', fontSize: 12 }} />
            <Tooltip cursor={false} isAnimationActive={false} content={<CustomTooltip />} />
            {/* <Legend verticalAlign="top" height={36} layout={"vertical"} align={"right"}/> */}
            {mainParkingLots.map((area) => {

              let correctSite = false

              for (let j = 0; j < site.carParks.length; j++) {
                if (site.carParks[j].id == area.id) {
                  correctSite = true
                }

              }

              let str = "url(#" + area.id + ")"

              if (correctSite) {
                return (
                  <Area type="monotone" name={area.name} stroke={area.graphColor} dataKey={area.id} fillOpacity={1} fill={str} dot={false} />
                )
              }



            }
            )

            }
          </AreaChart>
        </ResponsiveContainer>

      )
    }

    // Render the boxes with data for main areas with all sub areas

    function boxOther(site) {

      let rows = []
      let boxes = []

      if (mainParkingLots.length != 1) {

        for (let i = 0; i < mainParkingLots.length; i++) {

          let correctSite = false

          for (let j = 0; j < site.carParks.length; j++) {
            if (site.carParks[j].id == mainParkingLots[i].id) {
              correctSite = true
            }

          }

          if (correctSite == true) {

            for (let j = 0; j < subParkingLots.length; j++) {
              if (mainParkingLots[i].name == subParkingLots[j].parentElement) {

                let percentageStyle = {
                  background: 'linear-gradient(to right, lightcoral ' + Math.round(subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.realOccupiedPercent : 0) + '%,lightgreen 1%'
                };

                let tr = <tr className="padding">
                  <td className="td-first">{subParkingLots[j].name}</td>
                  <td>{subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.available : 0}</td>
                  <td>{subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.constructed : 0}</td>
                  <td style={{ color: 'red' }}>{subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.defect : 0}</td>
                  <td>{subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.reserved : 0}</td>
                  <td>{subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.exceeded : 0}</td>
                  <td style={percentageStyle}>
                    <span style={{ marginRight: '40px' }}>{subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.realOccupied : 0}</span>
                    <span style={{ position: 'absolute', right: '10px' }}>{Math.round(subParkingLots[j].areaValues != null ? subParkingLots[j].areaValues.realOccupiedPercent : 0)} %</span>
                  </td>
                </tr>
                rows.push(tr)

              }
            }


            let box = <div className="box-dashboard2">
              <div className="box-name">{mainParkingLots[i].name}</div>
              <SimpleBar style={{ height: 'calc( 100% - 20px )' }}>
                <div style={{ padding: '5px 5px 2px 5px' }}>
                  <table>
                    {thead()}
                    <tbody>
                      <tr>
                        <td className="td-first">{mainParkingLots[i].name}</td>
                        <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.available : 0}</td>
                        <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.constructed : 0}</td>
                        <td style={{ color: 'red' }}>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.defect : 0}</td>
                        <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.reserved : 0}</td>
                        <td>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.exceeded : 0}</td>
                        <td style={{ background: 'linear-gradient(to right, lightcoral ' + Math.round(mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.realOccupiedPercent : 0) + '%,lightgreen 1%' }}>
                          <span style={{ marginRight: '40px' }}>{mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.realOccupied : 0}</span>
                          <span style={{ position: 'absolute', right: '10px' }}>{Math.round(mainParkingLots[i].areaValues != null ? mainParkingLots[i].areaValues.realOccupiedPercent : 0)} %</span>
                        </td>
                      </tr>
                      <div style={{ width: '100%', height: '1px', backgroundColor: '#dadadb', position: 'absolute' }}></div>
                      {rows}
                    </tbody>
                  </table>
                </div>
              </SimpleBar>
            </div>

            boxes.push(box)
            rows = []
          }
        }
      }

      return (
        <div className="second-part">
          {boxes}
        </div>
      )

    }

    if (pageLoaded) {

      function infoShow() {
        $('.right-container-se').show()
        infoOpen = true
      }

      return (
        <div id="dashboard" className="left-container-se" style={{ backgroundColor: 'white' }}>
          <SimpleBar style={{ height: '100%' }}>
            <div className="info-icon-container">
              <img src={infoIcon} title="info" id="info-icon" style={{ height: '16px', width: '16px' }} onClick={() => infoShow()} />
            </div>
            {sites.map(item => (
              <>
                <div className="first-part">
                  {boxMain(item)}
                  {tablet == false && (
                    chart(item)
                  )}
                </div>
                {boxOther(item)}
                {tablet == true && (
                  chart(item)
                )}
              </>
            ))}


          </SimpleBar>
        </div>
      )
    }
  };

  // Settings on the right side with tables

  function settings() {

    function convertDate(date) {

      const currentDate = new Date(date);
      const currentDayOfMonth = currentDate.getDate();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();

      let minutes = currentDate.getMinutes();
      let hours = currentDate.getHours();
      let seconds = currentDate.getSeconds();
      let month
      let day

      if (currentDayOfMonth < 10) {
        day = "0" + currentDayOfMonth
      }

      else {
        day = currentDayOfMonth
      }

      if (currentMonth < 10) {
        month = "0" + currentMonth
      }

      else {
        month = currentMonth
      }


      if (hours < 10) {
        hours = "0" + hours
      }

      if (minutes < 10) {
        minutes = "0" + minutes
      }

      if (seconds < 10) {
        seconds = "0" + seconds
      }

      const dateTimeString = day + "." + month + "." + currentYear + " " + hours + ":" + minutes + ":" + seconds

      return dateTimeString

    }

    function timeExceedings() {

      function getParkingDuration(gotOccupied) {

        let parkingDuration

        let date = new Date(gotOccupied)
        let date2 = new Date()

        let diffMS = Math.abs(date2 - date);
        let days = Math.floor(diffMS / (24 * 60 * 60 * 1000));
        let daysms = diffMS % (24 * 60 * 60 * 1000);
        let hours = Math.floor((daysms) / (60 * 60 * 1000));
        let hoursms = diffMS % (60 * 60 * 1000);
        let minutes = Math.floor((hoursms) / (60 * 1000));

        let d = ('0' + days).slice(-2) + "d "
        if (days < 1) {
          d = ""
        }
        if (days > 99) {
          d = days + "d "
        }
        let h = ('0' + hours).slice(-2) + "h "
        let m = ('0' + minutes).slice(-2) + "m"

        parkingDuration = d + h + m

        return parkingDuration
      }



      return (
        <table>
          <thead>
            <tr >
              <th >{props.t('lot.lot')}</th>
              <th >{props.t('lot.parkBeginn')}</th>
              <th >{props.t('lot.parkDauer')}</th>
            </tr>
          </thead>
          <tbody>
            {exceeded.map((item) => (
              <>
                <tr>
                  <td>{item.name}</td>
                  <td>{convertDate(item.lastTimeLotGotOccupied)}</td>
                  <td>{getParkingDuration(item.lastTimeLotGotOccupied)}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      )
    }

    function specialSettings() {
      return (
        <table>
          <thead>
            <tr >
              <th>{props.t('lot.lot')}</th>
              <th>{props.t('dashboard.besonderheiten')}</th>
              <th>{props.t('allgemein.bemerkung')}</th>
            </tr>
          </thead>
          <tbody>


            {selected.includes("CustomerGroup") && (
              customerGroup.map((item) => (
                <>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.customerGroup != null ? props.t('elementeAllgemein.kundengruppe') + ": " + props.t('elementeAllgemein.kundengruppen.' + item.customerGroup) : ""}</td>
                    <td>{item.remark != null ? item.remark : ""} </td>
                  </tr>
                </>
              ))
            )}


            {selected.includes("LotType") && (
              lotType.map((item) => (
                <>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.lotType != null ? props.t('elementeAllgemein.typ') + ": " + props.t('elementeAllgemein.typen.' + item.lotType) : ""}</td>
                    <td>{item.remark != null ? item.remark : ""} </td>
                  </tr>
                </>
              ))
            )}

            {selected.includes("Mode") && (
              mode.map((item) => (
                <>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.mode == "0" ? props.t('allgemein.modus') + ": " + props.t('lot.modes.Automatic') : item.mode == "1" ? props.t('allgemein.modus') + ": " + props.t('lot.modes.ManualFree') : item.mode == "2" ? props.t('allgemein.modus') + ": " + props.t('lot.modes.ManualOccupied') : ""}</td>
                    <td>{item.remark != null ? item.remark : ""} </td>
                  </tr>
                </>
              ))
            )}

            {selected.includes("ParkingTimeRestrictionEnabled") && (
              parkingTimeRestrictionEnabled.map((item) => (
                <>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.parkingTimeRestrictionEnabled == true ? props.t('elementeAllgemein.zeitueberwachung') + ": " + props.t('allgemein.ja') : props.t('elementeAllgemein.zeitueberwachung') + ": " + props.t('allgemein.nein')}</td>
                    <td>{item.remark != null ? item.remark : ""} </td>
                  </tr>
                </>
              ))
            )}

            {selected.includes("Remark") && (
              remark.map((item) => (
                <>
                  <tr>
                    <td>{item.name}</td>
                    <td colspan="2">{item.remark != null ? props.t('allgemein.bemerkung') + ": " + item.remark : ""} </td>
                  </tr>
                </>
              ))
            )}

            {selected.includes("Reserved") && (
              reserved.map((item) => (
                <>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.reserved == true ? props.t('lot.reserviert') + ": " + props.t('allgemein.ja') : props.t('lot.reserviert') + ": " + props.t('allgemein.nein')}</td>
                    <td>{item.remark != null ? item.remark : ""} </td>
                  </tr>
                </>
              ))
            )}

          </tbody>
        </table>
      )
    }

    function lastActivity() {
      return (
        <table id="lastActivityDashboardTable">
          <thead>
            <tr >
              <th >{props.t('login.benutzer')}</th>
              <th >{props.t('kalender.element')}</th>
              <th colspan="2">{props.t('kalender.tableTimerList.headerAenderung')}</th>
              <th >{props.t('allgemein.zeitstempel')}</th>
            </tr>
          </thead>
          <tbody>
            {userLog.map((item) => (
              <>
                <tr>
                  <td>{item.userName}</td>
                  <td title={item.elementTyp}>{item.elementName}</td>
                  <td>{item.changeDescription}</td>
                  <td>{item.newValue}</td>
                  <td>{convertDate(item.timestamp)}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      )
    }

    if (pageLoaded == true) {
      return (
        <div className="right-container-se" id="info" ref={infoContainer}>
          <div className="content-name">{props.t('allgemein.info')}</div>
          <SimpleBar className="simplebar-se">
            <div className="content">

              <div className="content-group">
                <div className="content-group-name">{props.t('dashboard.ueberschritteneStellplaetze')}</div>
                <div className="content-areas-se" style={{ height: '200px' }}>
                  <SimpleBar style={{ height: '200px' }}>
                    {timeExceedings()}
                  </SimpleBar>
                </div>
              </div>


              <div className="content-group">
                <div className="content-group-name">{props.t('dashboard.sonderstellplaetze')}</div>



                <Select style={{ width: '100%' }} multiple value={selected} onChange={handleChange} input={<Input />}
                  renderValue={renderValue1}
                  MenuProps={MenuProps} >
                  {names.map((item) => (
                    <MenuItem key={item.label} value={item.value}>
                      <Checkbox color="primary" size="small" checked={selected.indexOf(item.value) > -1} />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  ))}
                </Select>



                <div className="content-areas-se" style={{ height: '200px', marginTop: '20px' }}>
                  <SimpleBar style={{ height: '200px' }}>
                    {specialSettings()}
                  </SimpleBar>
                </div>
              </div>

              <div className="content-group">
                <div className="content-group-name">{props.t('dashboard.letzteBenutzeraktivitaeten')}</div>
                <div className="content-areas-se" style={{ height: '200px', marginBottom: '15px' }}>
                  <SimpleBar style={{ height: '200px' }}>
                    {lastActivity()}
                  </SimpleBar>
                </div>
              </div>


            </div>
          </SimpleBar>
        </div>
      )
    }
  }

  return (
    <div className="pagewrapper-2">
      {loaderShow == true && (
        <div className="loader">
          <ReactLoading type={"bars"} color={"#2a3042"} height={80} width={80} name={"Loading"} />
        </div>
      )}
      {dashboard()}
      {settings()}
    </div>
  )

}

export default withNamespaces()(Dashboard)
