
import { withNamespaces } from 'react-i18next';
import $ from "jquery";
import SimpleBar from "simplebar-react";
import axios from "axios";

import ReactLoading from 'react-loading';
import jwt_decode from "jwt-decode";

import SelectSingle from "react-select";
import customStyles from '../../assets/select/customStyles'

import Token from "./quill/Token";
import Editor from "./quill/Editor";
import 'react-quill/dist/quill.snow.css';

import React, {useState, useEffect, useRef} from 'react';
import infoIcon from '../../assets/images/mgsuite/settings-icon.png';

import pic1 from '../../assets/images/mgsuite/CountingAreaReset_32x32.png';
import pic2 from '../../assets/images/mgsuite/Autofarben_32x32.png';
import pic3 from '../../assets/images/mgsuite/Helligkeitstimer_32x32.png';
import pic4 from '../../assets/images/mgsuite/Users_32x32.png';

import Checkbox from '@material-ui/core/Checkbox';
import { SketchPicker } from 'react-color';

import PopUp from "./Components/PopUp.js";
import CreateEditDeleteUser from "./Components/CreateEditDeleteUser.js";
import CreateEditDeleteTag from "./Components/CreateEditDeleteTag.js";
import PreCounter from "./Components/PreCounter.js";

let settingsOpen
let pageLoaded=true

// Colors for cars

let occupColor1="#008DD2"
let occupColor2="#D9DADA"

let occupResColor1="#008DD2"
let occupResColor2="#FFED00"

let occupExcColor1="#E5097F"
let occupExcColor2="#D9DADA"

let defColor1="#E31E24"
let defColor2="#D9DADA"

let freeColor1="white"
let freeColor2="white"

let freeResColor1="#FFED00"
let freeResColor2="#D9DADA"

let defResColor1="#E31E24"
let defResColor2="#FFED00"

let preSelectedColors=['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE',
'#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']

let statusNames=["car_B","car_BY","car_M","car_G","car_Y","car_R","car_RB"]
let activeParkStatusNames=["car_B","car_BY","car_M","car_Y","car_R","car_RB"]

let currentCar
let colorPickerPosition

const TOKENS = [
  { title: "CARPARK", slug: "CARPARK", id:"1"},
  { title: "ELEMENTNAME", slug: "ELEMENTNAME",id:"2"},
  { title: "MESSAGE", slug: "MESSAGE",id:"3"}
];

const tokensById = {};
TOKENS.forEach(token => {
  tokensById[token.id] = token;
});


let users=null

let optionsCustomerGroups=[]
let optionsTypes=[]

const Einstellungen= (props) => {

  let link = localStorage.getItem('link')
  const [token, setToken] = useState(localStorage.getItem('JWT'));

  let customerGroups=JSON.parse(localStorage.getItem('customerGroups'))
  let types=JSON.parse(localStorage.getItem('lotTypes'))

  const quillRef = useRef(null);
  const [html, setHtml] = useState("");
  const [value, setValue] = useState('');
  const [makeUpdate, setMakeUpdate] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const [mobile, setMobile] = useState(false);
  const settingsContainer = useRef(null);
  const sketchPickerRef = useRef(null);



  const [selectedType, setSelectedType] = useState();

  const [colorPickerShow, setColorPickerShow] = useState(false);

  let parkStatusNames=[props.t('administration.autofarben.car_B'),props.t('administration.autofarben.car_BY'),props.t('administration.autofarben.car_M'),
  props.t('administration.autofarben.car_G'), props.t('administration.autofarben.car_Y'), props.t('administration.autofarben.car_R'), props.t('administration.autofarben.car_RB')]

  const [currentColorPicker, setCurrentColorPicker] = useState();

  const [car_B_Color1, setCar_B_Color1] = useState(occupColor1);
  const [car_B_Color2, setCar_B_Color2] = useState(occupColor2);

  const [car_BY_Color1, setCar_BY_Color1] = useState(occupResColor1);
  const [car_BY_Color2, setCar_BY_Color2] = useState(occupResColor2);

  const [car_M_Color1, setCar_M_Color1] = useState(occupExcColor1);
  const [car_M_Color2, setCar_M_Color2] = useState(occupExcColor2);

  const [car_G_Color1, setCar_G_Color1] = useState(freeColor1);
  const [car_G_Color2, setCar_G_Color2] = useState(freeColor2);

  const [car_Y_Color1, setCar_Y_Color1] = useState(freeResColor1);
  const [car_Y_Color2, setCar_Y_Color2] = useState(freeResColor2);

  const [car_R_Color1, setCar_R_Color1] = useState(defColor1);
  const [car_R_Color2, setCar_R_Color2] = useState(defColor2);

  const [car_RB_Color1, setCar_RB_Color1] = useState(defResColor1);
  const [car_RB_Color2, setCar_RB_Color2] = useState(defResColor2);

  let allColors1=[car_B_Color1,car_BY_Color1,car_M_Color1,car_G_Color1,car_Y_Color1,car_R_Color1,car_RB_Color1]
  let allColors2=[car_B_Color2,car_BY_Color2,car_M_Color2,car_G_Color2,car_Y_Color2,car_R_Color2,car_RB_Color2]

  $('.einstellungen-container').css('background-color','unset').css('color','unset')
  $('#'+selectedType).css('background-color','#e9ecef').css('color','unset')

  const [popUpShow, setPopUpShow] = useState(false);
  const popUpContainer = useRef(null);

  function getNames() {

    optionsCustomerGroups=[]
    optionsTypes=[]

    for (let i =0;i < customerGroups.length; i++) {
      if (customerGroups[i].name.includes(".")) {

        optionsCustomerGroups.push (
          { value: customerGroups[i].id, label: <div className="flex-iac settings-item">{customerGroups[i].name}</div> }
        )
      }
      else {
     
        optionsCustomerGroups.push (
          { value: customerGroups[i].id, label: <div className="flex-iac settings-item">{props.t('elementeAllgemein.kundengruppen.' + customerGroups[i].name )}</div> }
        )
      }
    
    }

    for (let i =0;i < types.length; i++) {
      if (types[i].name.includes(".")) {
  
        optionsTypes.push (
          { value: types[i].id, label: <div className="flex-iac settings-item">{types[i].name}</div> }
        )
      }
      else {

        optionsTypes.push (
          { value: types[i].id, label: <div className="flex-iac settings-item">{props.t('elementeAllgemein.typen.' + types[i].name )}</div> }
        )
      }
    }

    setMakeUpdate(current => !current)

  }

  $(document).on('click', function(e){

    if (popUpContainer.current && !popUpContainer.current.contains(e.target) && e.target.id!='settings-icon' && !$(e.target).is('.settings-item')
    && !$(e.target).children().hasClass('settings-item') && !$(e.target).hasClass('select-icon') && !$(e.target).parents('.rc-time-picker-panel').length==1 && !$(e.target).hasClass('rc-time-picker-clear') 
    && !$(e.target).hasClass('rc-time-picker-clear-icon') && e.target.id!='pop-up-button' ){
   
      setPopUpShow(false)
    }


    if (settingsOpen==true && settingsContainer.current && !settingsContainer.current.contains(e.target) && e.target.id!='settings-icon' ) {
     $(".right-container-se").hide()
     settingsOpen=false
    }

     if (sketchPickerRef.current && !sketchPickerRef.current.contains(e.target) 
     && !$(e.target).hasClass('color-picker-container') && !$(e.target).hasClass('color-picker')
     && !$(e.target).hasClass('fa-caret-down') ) {
      setColorPickerShow(false) 
    }
    

  })

    useEffect(() => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');
    setToken(localStorage.getItem('JWT'))
  }, [localStorage.getItem('JWT')]);


  async function getData() {

    let tokenExpired=null
  
    function delay(timer) {
      return new Promise(resolve => {
          setTimeout(function () {      
            if (localStorage.getItem('JWT')) {
              let decoded = jwt_decode(localStorage.getItem('JWT'));
              let diff = decoded.exp * 1000 - Date.now() 
          
              if (diff <= 0) {
                tokenExpired=true
              }
              else {
                tokenExpired=false
              }
          }        
            resolve()}, timer);
      });
  };

    while (tokenExpired==null || tokenExpired==true)
        await delay(50);

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('JWT');

    axios.get(link+'/api/User')  
    .then((response) => { 
      users= response.data
      setMakeUpdate(current => !current)
    })
  }

  useEffect(() => {

  getData()
  // getNames()

  }, []);


    useEffect(() => { 

      if (window.innerWidth<813 && window.innerHeight<900) {
        setMobile(true)
      }

     }, []);


     function colorPicker() {


      function handleColorChange(value) {
        setCurrentColorPicker(value.hex)
        
        switch(currentCar) {
          case "car_B":
            setCar_B_Color1(value.hex)
            break;
          case "car_BY":
            setCar_BY_Color1(value.hex)
            break;
          case "car_M":
             setCar_M_Color1(value.hex)
            break;
           case "car_G":
            setCar_G_Color1(value.hex)
          break;
          case "car_Y":
            setCar_Y_Color1(value.hex)
            break;
          case "car_R":
            setCar_R_Color1(value.hex)
            break;
          case "car_RB":
             setCar_RB_Color1(value.hex)
            break;

            case "car_B_2":
              setCar_B_Color2(value.hex)
              break;
            case "car_BY_2":
              setCar_BY_Color2(value.hex)
              break;
            case "car_M_2":
               setCar_M_Color2(value.hex)
              break;
             case "car_G_2":
              setCar_G_Color2(value.hex)
            break;
            case "car_Y_2":
              setCar_Y_Color2(value.hex)
              break;
            case "car_R_2":
              setCar_R_Color2(value.hex)
              break;
            case "car_RB_2":
               setCar_RB_Color2(value.hex)
              break;
          default:
        }  
      }
        return (
          <div>
            
            {colorPickerShow ? (
               <div style={colorPickerPosition} ref={sketchPickerRef}>
                <SketchPicker
                  color={currentColorPicker}
                  presetColors={preSelectedColors}
                  onChange={handleColorChange}
                />
              </div>
            ) : null}
          </div>
        );
      }
    
  function optionView() {

    function carColors() {
      function car(color1,color2,name) {
        if (!activeParkStatusNames.includes(name)) {
         return (
           <svg class="nocar" style={{width:'95px', height:'40px'}} ><g  id={name} transform="translate(-15, -25) scale(0.05)">
           <path fill={color1} stroke="black" stroke-width="13.4745" d="M2074,607c-30,-24 -65,-35 -101,-41c-12,-9 -26,-16 -42,-17c-77,-3 -156,-4 -235,-4c-19,0 -36,3 -48,6c-257,-9 -506,-9 -751,-4c-13,-7 -60,-8 -97,-7l-147,3c-45,1 -78,17 -119,22c-61,8 -117,21 -142,51c-39,46 -58,159 -58,272c0,113 19,226 58,271c25,30 81,42 142,50c41,6 75,22 119,22l147,3c37,1 84,0 97,-7c245,4 494,5 751,-4c12,3 29,6 48,6c79,0 158,-1 235,-4c16,-1 30,-8 42,-17c36,-6 70,-18 101,-42c72,-56 107,-168 107,-280c0,-112 -35,-224 -107,-279z"></path>
           <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1600,587c-336,-11 -671,-8 -1003,16c-34,4 -33,12 -22,17c15,7 45,19 60,25c13,6 49,11 69,11c136,4 282,8 420,9c106,1 214,-7 274,-21c80,-19 201,-47 203,-57l-1,0zm1,598c-336,11 -671,8 -1003,-16c-34,-4 -33,-12 -22,-17c15,-7 45,-19 60,-25c13,-6 49,-11 69,-11c136,-4 282,-8 420,-9c106,-1 214,7 274,21c80,19 201,47 203,57l-1,0z"></path>
           <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M576,1161l-2,-1l-2,-1c0,-1 7,-4 8,-4c28,-11 54,-26 84,-30c34,-6 70,-5 105,-7c128,-4 257,-7 385,-7c83,2 169,4 250,22c56,14 113,26 169,44c5,2 10,4 15,5c-38,1 -76,2 -114,3c-31,1 -63,1 -94,2c-260,3 -523,-2 -782,-21c-7,-1 -15,-2 -21,-4l-1,-1zm1,-549l-2,1l-2,1c0,1 7,4 8,4c28,11 54,26 84,30c34,6 70,5 105,7c128,4 257,7 385,7c83,-2 169,-4 250,-22c56,-14 113,-26 169,-44c5,-2 10,-4 15,-5c-38,-1 -76,-2 -114,-3c-31,-1 -63,-1 -94,-2c-260,-3 -523,2 -782,21c-7,1 -15,2 -21,4l-1,1z"></path>
           <path fill= {color2} stroke="black" stroke-width="2.52646" d="M914,655l-45,-41l4,-18c94,-4 188,-6 283,-6l-19,68c-55,0 -110,0 -164,-2c-19,0 -39,-1 -58,-1l-1,0zm1,463l-45,41l4,18c94,4 188,6 283,6l-19,-68c-55,0 -110,0 -164,2c-19,0 -39,1 -58,1l-1,0zm-322,-422c-26,127 -29,255 0,382c-1,13 0,19 -5,22l-108,27c-29,7 -37,-8 -42,-25c-43,-141 -45,-288 0,-428c5,-16 12,-32 42,-25l109,27c5,2 4,8 5,22l-1,-2zm800,414c54,7 190,44 233,56c105,-69 112,-495 0,-559c-43,11 -175,46 -233,56c38,153 38,294 0,447zm-180,74l-40,-69c2,0 3,0 5,0c60,1 120,4 180,13c51,9 101,22 151,35l6,17c-100,2 -201,4 -301,4l-1,0zm-323,-65l-64,57c-52,-2 -104,-5 -156,-8c-29,-2 -60,-2 -89,-7l-2,-1c15,-7 32,-14 47,-20c29,-11 58,-14 88,-16c58,-2 116,-3 174,-5l2,0zm323,-529l-40,69c2,0 3,0 5,0c60,-1 120,-4 180,-13c51,-9 101,-22 151,-35l6,-17c-100,-2 -201,-4 -301,-4l-1,0zm-323,65l-64,-57c-52,2 -104,5 -156,8c-29,2 -60,2 -89,7l-2,1c15,7 32,14 47,20c29,11 58,14 88,16c58,2 116,3 174,5l2,0z"></path>
           <path fill="#C5C6C6" stroke="black" stroke-width="2.52646" d="M468,608c-39,10 -64,77 -74,113c-3,9 -15,15 -27,17c11,-56 51,-134 101,-149c13,1 13,4 19,8c4,2 -7,7 -19,10l0,1zm0,556c-39,-10 -64,-77 -74,-113c-3,-9 -15,-15 -27,-17c11,56 51,134 101,149c13,-1 13,-4 19,-8c4,-2 -7,-7 -19,-10l0,-1z"></path>
           <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1162,1111l9,0l45,76l-9,0l-45,-76zm823,73c33,-15 100,-75 107,-115c16,-7 33,-12 46,-17c-6,40 -63,120 -132,143c-15,0 -13,0 -24,0c-10,0 -6,-6 3,-11zm0,-595c33,15 100,75 107,115c16,7 33,12 46,17c-6,-40 -63,-120 -132,-143c-15,0 -13,0 -24,0c-10,0 -6,6 3,11zm-1091,69l14,1l-75,-65l-10,1l71,64l0,-1zm268,4l9,0l45,-76l-9,0l-45,76zm-26,0l9,0l21,-76l-9,0l-21,76zm-242,453l14,-1l-75,65l-10,-1l71,-64l0,1zm242,-4l9,0l21,76l-9,0l-21,-76z"></path>
           <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M2168,769c9,37 13,77 13,117c0,2 0,5 0,6l0,0c0,37 -5,75 -13,110c-9,12 -24,19 -30,19c-16,0 -16,-10 -13,-26c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -2,-26 13,-26c5,0 21,7 30,19l0,1z"></path>
           <path fill="#434242" stroke="black" stroke-width="2.52646" d="M2169,773c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-10,9 -24,14 -28,14c-7,0 -9,0 -6,-17c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -1,-17 6,-17c4,0 18,5 28,14l0,1z"></path>
           <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M661,1095c210,0 394,-3 604,2c9,0 6,-9 0,-11c-204,-5 -390,-2 -604,-2l0,11zm1508,-322c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-3,3 -7,5 -10,7c9,-40 13,-83 12,-126l0,0c0,-39 -4,-77 -12,-113c3,2 7,4 10,7zm-1508,-96c210,0 394,3 604,-2c9,0 6,9 0,11c-204,5 -390,2 -604,2l0,-11z"></path>
           <path fill="none" stroke="black" stroke-width="3.36862" d="M639,687c-10,51 -13,116 -13,191m639,216c46,1 87,7 126,15m-126,-430c46,-1 87,-7 126,-15m-476,-9l-12,0m243,3l18,0m470,-44c161,20 313,50 459,90m-467,-97c161,20 318,49 464,90m-894,-153c-10,13 -12,23 -15,42m411,6c12,2 23,6 32,14m-8,-57c-5,16 -10,29 -18,38m47,-37c106,12 217,16 330,17m-1483,25c98,-14 207,-21 319,-27c266,-9 534,-9 803,-3c117,3 237,10 359,20m-1389,-28c62,8 273,2 310,-7m-78,48c-8,-10 -10,-23 -9,-34c14,-13 38,-15 63,-19m292,48l40,0m-335,24l-19,-17m-497,292c2,-45 4,-76 9,-118c1,-12 3,-23 4,-32m11,153c2,-56 6,-120 18,-171m442,-123l35,-1m-235,490c-10,-51 -13,-116 -13,-191m-284,-21c3,80 10,147 21,194c6,24 10,36 23,38c19,3 46,7 64,7m143,-25l68,2m1475,-36l19,-5m-530,118c161,-20 318,-49 464,-90m-456,83c161,-20 313,-50 459,-90m-500,112c12,-2 23,-6 32,-14m-429,63c-10,-13 -12,-23 -15,-42m-362,-9c-8,10 -10,23 -9,34c14,13 38,15 63,19m-286,-12c62,-8 273,-2 310,7m-403,-45c98,14 207,21 319,27c266,9 534,9 803,3c117,-3 237,-10 359,-20m-329,31c106,-12 217,-16 330,-17m-359,17c-5,-16 -10,-29 -18,-38m-434,-2l40,0m-59,-69l18,0m-294,45l-19,17m64,-58l-12,0m-65,57l35,1m-495,-295c2,56 6,120 18,171m-42,-168c2,45 4,76 9,118c1,12 3,23 4,32m1772,-13l-11,36m-1535,-370l68,-2m-320,215c3,-80 10,-147 21,-194c6,-24 10,-36 23,-38c19,-3 46,-7 64,-7"></path>
           <path fill="#FEFEFE" stroke="black" stroke-width="2.52646" d="M1486,512c13,38 30,71 43,88c5,6 20,-1 27,-5c-7,-22 -37,-71 -43,-75c-7,-6 -17,-9 -27,-8zm0,748c13,-38 30,-71 43,-88c5,-6 20,1 27,5c-7,22 -37,71 -43,75c-7,6 -17,9 -27,8z"></path>
         </g></svg>
          )
        }
        else {
        return (
         <svg style={{width:'95px', height:'40px'}}><g id={name} transform="translate(-15, -25) scale(0.05)">
         <path fill={color1} stroke="black" stroke-width="13.4745" d="M2074,607c-30,-24 -65,-35 -101,-41c-12,-9 -26,-16 -42,-17c-77,-3 -156,-4 -235,-4c-19,0 -36,3 -48,6c-257,-9 -506,-9 -751,-4c-13,-7 -60,-8 -97,-7l-147,3c-45,1 -78,17 -119,22c-61,8 -117,21 -142,51c-39,46 -58,159 -58,272c0,113 19,226 58,271c25,30 81,42 142,50c41,6 75,22 119,22l147,3c37,1 84,0 97,-7c245,4 494,5 751,-4c12,3 29,6 48,6c79,0 158,-1 235,-4c16,-1 30,-8 42,-17c36,-6 70,-18 101,-42c72,-56 107,-168 107,-280c0,-112 -35,-224 -107,-279z"></path>
         <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1600,587c-336,-11 -671,-8 -1003,16c-34,4 -33,12 -22,17c15,7 45,19 60,25c13,6 49,11 69,11c136,4 282,8 420,9c106,1 214,-7 274,-21c80,-19 201,-47 203,-57l-1,0zm1,598c-336,11 -671,8 -1003,-16c-34,-4 -33,-12 -22,-17c15,-7 45,-19 60,-25c13,-6 49,-11 69,-11c136,-4 282,-8 420,-9c106,-1 214,7 274,21c80,19 201,47 203,57l-1,0z"></path>
         <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M576,1161l-2,-1l-2,-1c0,-1 7,-4 8,-4c28,-11 54,-26 84,-30c34,-6 70,-5 105,-7c128,-4 257,-7 385,-7c83,2 169,4 250,22c56,14 113,26 169,44c5,2 10,4 15,5c-38,1 -76,2 -114,3c-31,1 -63,1 -94,2c-260,3 -523,-2 -782,-21c-7,-1 -15,-2 -21,-4l-1,-1zm1,-549l-2,1l-2,1c0,1 7,4 8,4c28,11 54,26 84,30c34,6 70,5 105,7c128,4 257,7 385,7c83,-2 169,-4 250,-22c56,-14 113,-26 169,-44c5,-2 10,-4 15,-5c-38,-1 -76,-2 -114,-3c-31,-1 -63,-1 -94,-2c-260,-3 -523,2 -782,21c-7,1 -15,2 -21,4l-1,1z"></path>
         <path fill= {color2} stroke="black" stroke-width="2.52646" d="M914,655l-45,-41l4,-18c94,-4 188,-6 283,-6l-19,68c-55,0 -110,0 -164,-2c-19,0 -39,-1 -58,-1l-1,0zm1,463l-45,41l4,18c94,4 188,6 283,6l-19,-68c-55,0 -110,0 -164,2c-19,0 -39,1 -58,1l-1,0zm-322,-422c-26,127 -29,255 0,382c-1,13 0,19 -5,22l-108,27c-29,7 -37,-8 -42,-25c-43,-141 -45,-288 0,-428c5,-16 12,-32 42,-25l109,27c5,2 4,8 5,22l-1,-2zm800,414c54,7 190,44 233,56c105,-69 112,-495 0,-559c-43,11 -175,46 -233,56c38,153 38,294 0,447zm-180,74l-40,-69c2,0 3,0 5,0c60,1 120,4 180,13c51,9 101,22 151,35l6,17c-100,2 -201,4 -301,4l-1,0zm-323,-65l-64,57c-52,-2 -104,-5 -156,-8c-29,-2 -60,-2 -89,-7l-2,-1c15,-7 32,-14 47,-20c29,-11 58,-14 88,-16c58,-2 116,-3 174,-5l2,0zm323,-529l-40,69c2,0 3,0 5,0c60,-1 120,-4 180,-13c51,-9 101,-22 151,-35l6,-17c-100,-2 -201,-4 -301,-4l-1,0zm-323,65l-64,-57c-52,2 -104,5 -156,8c-29,2 -60,2 -89,7l-2,1c15,7 32,14 47,20c29,11 58,14 88,16c58,2 116,3 174,5l2,0z"></path>
         <path fill="#C5C6C6" stroke="black" stroke-width="2.52646" d="M468,608c-39,10 -64,77 -74,113c-3,9 -15,15 -27,17c11,-56 51,-134 101,-149c13,1 13,4 19,8c4,2 -7,7 -19,10l0,1zm0,556c-39,-10 -64,-77 -74,-113c-3,-9 -15,-15 -27,-17c11,56 51,134 101,149c13,-1 13,-4 19,-8c4,-2 -7,-7 -19,-10l0,-1z"></path>
         <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M1162,1111l9,0l45,76l-9,0l-45,-76zm823,73c33,-15 100,-75 107,-115c16,-7 33,-12 46,-17c-6,40 -63,120 -132,143c-15,0 -13,0 -24,0c-10,0 -6,-6 3,-11zm0,-595c33,15 100,75 107,115c16,7 33,12 46,17c-6,-40 -63,-120 -132,-143c-15,0 -13,0 -24,0c-10,0 -6,6 3,11zm-1091,69l14,1l-75,-65l-10,1l71,64l0,-1zm268,4l9,0l45,-76l-9,0l-45,76zm-26,0l9,0l21,-76l-9,0l-21,76zm-242,453l14,-1l-75,65l-10,-1l71,-64l0,1zm242,-4l9,0l21,76l-9,0l-21,-76z"></path>
         <path fill="#EBECEC" stroke="black" stroke-width="2.52646" d="M2168,769c9,37 13,77 13,117c0,2 0,5 0,6l0,0c0,37 -5,75 -13,110c-9,12 -24,19 -30,19c-16,0 -16,-10 -13,-26c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -2,-26 13,-26c5,0 21,7 30,19l0,1z"></path>
         <path fill="#434242" stroke="black" stroke-width="2.52646" d="M2169,773c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-10,9 -24,14 -28,14c-7,0 -9,0 -6,-17c3,-19 3,-75 4,-117l0,0c0,-40 -1,-86 -4,-103c-3,-17 -1,-17 6,-17c4,0 18,5 28,14l0,1z"></path>
         <path fill="#9D9E9E" stroke="black" stroke-width="2.52646" d="M661,1095c210,0 394,-3 604,2c9,0 6,-9 0,-11c-204,-5 -390,-2 -604,-2l0,11zm1508,-322c8,36 12,75 12,113c0,2 0,5 0,6l0,0c0,36 -5,72 -12,106c-3,3 -7,5 -10,7c9,-40 13,-83 12,-126l0,0c0,-39 -4,-77 -12,-113c3,2 7,4 10,7zm-1508,-96c210,0 394,3 604,-2c9,0 6,9 0,11c-204,5 -390,2 -604,2l0,-11z"></path>
         <path fill="none" stroke="black" stroke-width="3.36862" d="M639,687c-10,51 -13,116 -13,191m639,216c46,1 87,7 126,15m-126,-430c46,-1 87,-7 126,-15m-476,-9l-12,0m243,3l18,0m470,-44c161,20 313,50 459,90m-467,-97c161,20 318,49 464,90m-894,-153c-10,13 -12,23 -15,42m411,6c12,2 23,6 32,14m-8,-57c-5,16 -10,29 -18,38m47,-37c106,12 217,16 330,17m-1483,25c98,-14 207,-21 319,-27c266,-9 534,-9 803,-3c117,3 237,10 359,20m-1389,-28c62,8 273,2 310,-7m-78,48c-8,-10 -10,-23 -9,-34c14,-13 38,-15 63,-19m292,48l40,0m-335,24l-19,-17m-497,292c2,-45 4,-76 9,-118c1,-12 3,-23 4,-32m11,153c2,-56 6,-120 18,-171m442,-123l35,-1m-235,490c-10,-51 -13,-116 -13,-191m-284,-21c3,80 10,147 21,194c6,24 10,36 23,38c19,3 46,7 64,7m143,-25l68,2m1475,-36l19,-5m-530,118c161,-20 318,-49 464,-90m-456,83c161,-20 313,-50 459,-90m-500,112c12,-2 23,-6 32,-14m-429,63c-10,-13 -12,-23 -15,-42m-362,-9c-8,10 -10,23 -9,34c14,13 38,15 63,19m-286,-12c62,-8 273,-2 310,7m-403,-45c98,14 207,21 319,27c266,9 534,9 803,3c117,-3 237,-10 359,-20m-329,31c106,-12 217,-16 330,-17m-359,17c-5,-16 -10,-29 -18,-38m-434,-2l40,0m-59,-69l18,0m-294,45l-19,17m64,-58l-12,0m-65,57l35,1m-495,-295c2,56 6,120 18,171m-42,-168c2,45 4,76 9,118c1,12 3,23 4,32m1772,-13l-11,36m-1535,-370l68,-2m-320,215c3,-80 10,-147 21,-194c6,-24 10,-36 23,-38c19,-3 46,-7 64,-7"></path>
         <path fill="#FEFEFE" stroke="black" stroke-width="2.52646" d="M1486,512c13,38 30,71 43,88c5,6 20,-1 27,-5c-7,-22 -37,-71 -43,-75c-7,-6 -17,-9 -27,-8zm0,748c13,-38 30,-71 43,-88c5,-6 20,1 27,5c-7,22 -37,71 -43,75c-7,6 -17,9 -27,8z"></path>
       </g></svg>
        )
      }
      }


      function carTbody() {

        function clickColorPicker(car) {
       
          currentCar=car

          switch(currentCar) {
            case "car_B":
              setCurrentColorPicker(car_B_Color1)
              break;
            case "car_BY":
              setCurrentColorPicker(car_BY_Color1)
              break;
            case "car_M":
              setCurrentColorPicker(car_M_Color1)
              break;
             case "car_G":
              setCurrentColorPicker(car_G_Color1)
            break;
            case "car_Y":
              setCurrentColorPicker(car_Y_Color1)
              break;
            case "car_R":
              setCurrentColorPicker(car_R_Color1)
              break;
            case "car_RB":
              setCurrentColorPicker(car_RB_Color1)
              break;
  
              case "car_B_2":
                setCurrentColorPicker(car_B_Color2)
                break;
              case "car_BY_2":
                setCurrentColorPicker(car_BY_Color2)
                break;
              case "car_M_2":
                setCurrentColorPicker(car_M_Color2)
                break;
               case "car_G_2":
                setCurrentColorPicker(car_G_Color2)
              break;
              case "car_Y_2":
                setCurrentColorPicker(car_Y_Color2)
                break;
              case "car_R_2":
                setCurrentColorPicker(car_R_Color2)
                break;
              case "car_RB_2":
                setCurrentColorPicker(car_RB_Color2)
                break;
            default:
              
          }
          setColorPickerShow(true)
        }

        let tr=[]

        function checkCarStatus(checked,statusName) {
          if (checked==true) {
            activeParkStatusNames = activeParkStatusNames.filter(v => v !== statusName)
          }
          else {
            activeParkStatusNames.push(statusName)
          }
          setMakeUpdate(current => !current)
        }

        function colorPickerCrd(e) {
          let posX = e.pageX-100;
          let posY = e.pageY-50;

          colorPickerPosition={ top:posY, left:posX,position:'absolute',zIndex:'10000'}

          if (window.innerWidth<813 && window.innerHeight<900) {
          colorPickerPosition={ position:'absolute', zIndex:'10000',height:'300px',width:'200px',margin:'auto',top:0,
           bottom:0,left:0,right:0}}
          setMakeUpdate(current => !current)
        }

       for (let i=0; i < parkStatusNames.length;i++) {
         let checked
     
         if (activeParkStatusNames.includes(statusNames[i])) {
          checked=true
         }
         else {
           checked=false
         }

         tr.push(
          <tr>
          <td>{parkStatusNames[i]}</td>
          <td> 
          <Checkbox checked={checked} color="primary" size="small" onClick={()=>checkCarStatus(checked,statusNames[i])}/>
          </td>
          <td>
            <div onClick={colorPickerCrd}>
             <div className="color-picker-container" onClick={()=>clickColorPicker(statusNames[i])}>
            <div className="color-picker" style={{ backgroundColor: allColors1[i]}}></div>
            <i style={{marginLeft:'5px',fontSize:'12px'}}class="fas fa-caret-down"></i>
            </div>
            </div>
            </td>
          <td>
          <div onClick={colorPickerCrd}>
          <div className="color-picker-container" onClick={()=>clickColorPicker(statusNames[i]+"_2")}>
            <div className="color-picker" style={{ backgroundColor: allColors2[i]}}></div>
            <i style={{marginLeft:'5px',fontSize:'12px'}}class="fas fa-caret-down"></i>
            </div>
            </div>
            </td>
          <td>{car(allColors1[i],allColors2[i],statusNames[i])}</td>
        </tr>
         )
       }
         
        return (
          <tbody>
             {tr}
        </tbody>
        )
      }


      return (
        <div className="einstellungen-box">
        <div className="box-name">{props.t('administration.carColors')}</div>
        <div style={{overflow:'auto'}}>
        <table id="carColorsTable">
          <thead>
          <tr>
            <th>{props.t('administration.autofarben.tableHeaderLotStatus')}</th>
            <th>{props.t('administration.autofarben.tableHeaderAutoZeigen')}</th>
            <th>{props.t('administration.autofarben.tableHeaderKarosserie')}</th>
            <th>{props.t('administration.autofarben.tableHeaderFenster')}</th>
            <th>{props.t('administration.autofarben.tableHeaderVorschau')}</th>
          </tr>
          </thead>
          {carTbody()}
          <tfoot>
			<tr>
				<td colspan="5">
          <div style={{display:'flex',justifyContent:'flex-end'}}>
          <button className="button" >{props.t('allgemein.speichern')}</button> 
          </div>
        
        </td>
			</tr>
		</tfoot>
        </table>
      </div>
        </div>
      )
    }


    function countingAreaReset() {


      return (
        <div className="einstellungen-box">
        <div className="box-name">{props.t('administration.countingAreaReset')}</div>
        <div style={{overflow:'auto'}}>
      <table id="countingAreaResetTable">
      <thead>
      <tr>
        <th colspan="2" style={{textAlign:'center'}}>{props.t('elementeAllgemein.area')}</th>
      <th colspan="5" style={{textAlign:'center'}}>{props.t('allgemein.timer')}</th>
      </tr>
      <tr>
            <td>{props.t('elementeAllgemein.areaPfad')}</td>
            <td>{props.t('elementeAllgemein.area')}</td>
            <td>{props.t('occupancy.einstellung_helligkeitstimer.wochentage')}</td>
            <td>{props.t('administration.countingAreas.uhrzeit')}</td>
            <td>{props.t('administration.countingAreas.wert')}</td>
            <td>{props.t('allgemein.aktiv')}</td>
            <td>
            <button className="button" id="pop-up-button" style={{width:'50px'}} onClick={()=>setPopUpShow(true)} >{props.t('allgemein.neu')}</button> 
              </td>
            </tr>
      </thead>
      <tbody>
        </tbody>
    </table>
    </div>
    </div>
      )
    }


    function editTypes() {

      function colorPickerCrd(e) {
        let posX = e.pageX-100;
        let posY = e.pageY-50;

        colorPickerPosition={ top:posY, left:posX,position:'absolute',zIndex:'10000'}

        if (window.innerWidth<813 && window.innerHeight<900) {
        colorPickerPosition={ position:'absolute', zIndex:'10000',height:'300px',width:'200px',margin:'auto',top:0,
         bottom:0,left:0,right:0}}
        setMakeUpdate(current => !current)
      }

      function addItems() {
        let items = []

        for (let i =0;i < types.length;i++) {
          let name
          if (types[i].name.includes(".")) {
            name=types[i].name
          }
          else {
            name=props.t('elementeAllgemein.typen.' + types[i].name )
          }

          items.push(
            <div style={{width:'90%',display:'flex',alignItems:'center',marginTop:'5px'}}>
            <div style={{width:'30%'}}>{name}</div>
                      <div onClick={colorPickerCrd} style={{marginLeft:'20px'}}>
            <div className="color-picker-container" onClick={()=>setColorPickerShow(true)}>
              <div className="color-picker" style={{ backgroundColor: allColors2[2]}}></div>
              <i style={{marginLeft:'5px',fontSize:'12px'}}class="fas fa-caret-down"></i>
              </div>
              </div> 
              </div>
          )
        }

        return (
          <div>
          <div style={{width:'100%',marginBottom:'10px',marginTop:'10px',borderBottom:'1px solid #dadadb'}}>Hintergrundfarbe</div>
           {items}
           <div style={{display:'flex',justifyContent:'flex-start'}}>
            <button className="button" style={{marginTop:'20px'}}>{props.t('allgemein.speichern')}</button> 
            </div>
          </div>
        )

      }


      return (
        <div className="einstellungen-box" style={{width:'500px',height:'500px'}} >
        <div className="box-name">Typen</div>
        <div style={{margin:'auto',width:'90%'}}>
      
          <div style={{width:'100%',marginBottom:'10px',marginTop:'10px',borderBottom:'1px solid #dadadb'}}>Neue Typen</div>
            <div style={{marginTop:'20px',display:'flex'}} >
              <input type="text" /> 
              <button className="button" style={{marginLeft:'20px'}} >Speichern</button> 
            </div>
            {addItems()}
    </div>
    </div>
      )
    }

    
    function editCustomerGroups() {

      function colorPickerCrd(e) {
        let posX = e.pageX-100;
        let posY = e.pageY-50;

        colorPickerPosition={ top:posY, left:posX,position:'absolute',zIndex:'10000'}

        if (window.innerWidth<813 && window.innerHeight<900) {
        colorPickerPosition={ position:'absolute', zIndex:'10000',height:'300px',width:'200px',margin:'auto',top:0,
         bottom:0,left:0,right:0}}
        setMakeUpdate(current => !current)
      }

      function addItems() {
        let items = []

        for (let i =0;i < customerGroups.length;i++) {
          let name
          if (customerGroups[i].name.includes(".")) {
            name=customerGroups[i].name
          }
          else {
            name=props.t('elementeAllgemein.kundengruppen.' + customerGroups[i].name )
          }

          items.push(
            <div style={{width:'90%',display:'flex',alignItems:'center',marginTop:'5px'}}>
            <div style={{width:'30%'}}>{name}</div>
                      <div onClick={colorPickerCrd} style={{marginLeft:'20px'}}>
            <div className="color-picker-container" onClick={()=>setColorPickerShow(true)}>
              <div className="color-picker" style={{ backgroundColor: allColors2[2]}}></div>
              <i style={{marginLeft:'5px',fontSize:'12px'}}class="fas fa-caret-down"></i>
              </div>
              </div> 
              </div>
          )
        }

        return (
          <div>
          <div style={{width:'100%',marginBottom:'10px',marginTop:'10px',borderBottom:'1px solid #dadadb'}}>Hintergrundfarbe</div>
           {items}
           <div style={{display:'flex',justifyContent:'flex-start'}}>
            <button className="button" style={{marginTop:'20px'}}>{props.t('allgemein.speichern')}</button> 
            </div>
          </div>
        )

      }

      return (
        <div className="einstellungen-box" style={{width:'500px',height:'500px'}} >
        <div className="box-name">Gruppen</div>
        <div style={{margin:'auto',width:'90%'}}>
      
          <div style={{width:'100%',marginBottom:'10px',marginTop:'10px',borderBottom:'1px solid #dadadb'}}>Neue Gruppen</div>
            <div style={{marginTop:'20px',display:'flex'}} >
              <input type="text" /> 
              <button className="button" style={{marginLeft:'20px'}} >Speichern</button> 
            </div>
            {addItems()}
    </div>
    </div>
      )
    }


    function lightIntensityTimer() {
      return (
        <div className="einstellungen-box">
        <div className="box-name">{props.t('administration.helligkeitstimer')}</div>  
        <div style={{overflow:'auto'}}>
      <table id="lightIntensityTimerTable">
      <thead>
      <tr>
        <th colspan="2" style={{textAlign:'center'}}>{props.t('allgemein.bereich')}</th>
      <th colspan="6" style={{textAlign:'center'}}>{props.t('allgemein.dimmer')}</th>
      </tr>
      <tr>
            <td></td>
            <td>{props.t('elementeAllgemein.helligkeitInProzent')}</td>
            <td>{props.t('occupancy.einstellung_helligkeitstimer.gueltigFuerSensoren')}</td>
            <td>{props.t('occupancy.einstellung_helligkeitstimer.gueltigFuerSchilder')}</td>
            <td>{props.t('allgemein.von')}</td>
            <td>{props.t('allgemein.bis')}</td>
            <td>{props.t('occupancy.einstellung_helligkeitstimer.wochentage')}</td>
            <td>
            <button className="button" id="pop-up-button" style={{width:'50px'}} onClick={()=>setPopUpShow(true)}>{props.t('allgemein.neu')}</button> 
              </td>
            </tr>
      </thead>
      <tbody> 
        </tbody>
    </table>
    </div>
    </div>
      )
    }


    function emailNotifications() {

      function handleTextChange(value) {
        setValue(value)
      }

      return (
        <div className="email-container">

    <div style={{display:'flex', flexDirection:'column'}}>
     <div className="email-header">
   <div> 
     An:
   <input type="text" className="email-header-input"></input>
   </div>
   </div>

     <div style={{display:'flex'}}>
        <div className="token-list">
          <SimpleBar style={{height:'100%',width:'100%'}}>
          {TOKENS.map(tokenProps => (
            <div style={{marginTop:'10px',marginLeft:'5px'}}>
            <Token {...tokenProps} key={tokenProps.id} quillRef={quillRef} /> 
            </div>
          ))}
          </SimpleBar>
        </div>
<div className="quill-box">
<Editor value={html} onChange={setHtml} quillRef={quillRef}  tokensById={tokensById}/>
        </div>
</div>

<div className="email-footer">
      <button className="button" >{props.t('allgemein.speichern')}</button> 
      </div>


</div>

      <div className="einstellungen-box einstellungen-box-email">
      <div className="box-name">{props.t('administration.personalSettings.emailBenachrichtigungen')}</div>  
      <div style={{padding:'20px'}}>
      <div className="content-group-name">{props.t('emailservice.fehler.fehler')}</div> 
      <div className="flex-sb">
      <div>{props.t('emailservice.fehler.StellplatzDefekt')}</div>
      <Checkbox color="primary" size="small"/>
      </div>
      <div className="flex-sb">
      <div>{props.t('emailservice.fehler.SchildDefekt')}</div>
      <Checkbox color="primary" size="small"/>
      </div>
      <div className="flex-sb">
      <div>{props.t('emailservice.fehler.VerbindungProcessEngineVerloren')}</div>
      <Checkbox color="primary" size="small"/>
      </div>
      <div className="flex-sb">
      <div>{props.t('emailservice.fehler.GatewayDefekt')}</div>
      <Checkbox color="primary" size="small"/>
      </div>
      <div style={{marginTop:'15px'}}className="content-group-name">{props.t('emailservice.events.events')} </div> 
      <div className="flex-sb">
      <div>{props.t('emailservice.events.ZeitueberschreitungParkplatz')}</div>
      <Checkbox color="primary" size="small"/>
      </div>



      <div style={{marginTop:'15px'}}className="content-group-name">{props.t('allgemein.einstellungen')} </div> 
      <div className="flex-sb">
      <div>UseSSL</div>
      <Checkbox color="primary" size="small"/>
      </div>
      <div className="flex-sb" style={{marginTop:'10px'}}>
      <div>Server :</div>
      <input type="text" style={{width:'70%'}}></input>
      </div>
      <div className="flex-sb" style={{marginTop:'10px'}}>
      <div>Port :</div>
      <input type="text" style={{width:'70%'}}></input>
      </div>
      <div className="flex-sb" style={{marginTop:'10px'}}>
      <div>User :</div>
      <input type="text" style={{width:'70%'}}></input>
      </div>
      <div className="flex-sb" style={{marginTop:'10px'}}>
      <div>{props.t('login.labelPasswort')} :</div>
      <input type="password" autoComplete="new-password" style={{width:'70%'}}></input>
      </div>
    


      <div style={{display:'flex',justifyContent:'flex-end'}}>
      <button className="button" style={{marginTop:'10px'}} >{props.t('allgemein.speichern')}</button> 
      </div>
      
      </div>
      </div> 


</div>
        )
    }


   
    function renderView() {
      if (selectedType=="carColors") {
        return (
          carColors()
         )
      }
      else if (selectedType=="countingAreaReset") {
        return (
          countingAreaReset()
         )
      }

      else if (selectedType=="createEditDeleteUser" && users!=null) {
        return (
          <CreateEditDeleteUser data={users} onChange={()=> getData()} > </CreateEditDeleteUser> 
         )
      }

      else if (selectedType=="editCustomerGroups") {
        return (
          editCustomerGroups()
         )
        }

        else if (selectedType=="editTypes") {
          return (
            editTypes()
           )
          }

      else if (selectedType=="emailNotifications") {
        return (
          emailNotifications()
         )
      }
      else if (selectedType=="preCounter") {
        return (
          <PreCounter> </PreCounter> 
         )
      }
      else if (selectedType=="tags") {
        return (
          <CreateEditDeleteTag > </CreateEditDeleteTag> 
         )
      }
    }
    if(pageLoaded) {

      function settingsShow(){
        $('.right-container-se').show()
        settingsOpen=true
        setPopUpShow(false)
      }

    return (
        <div className="left-container-se settings-left">
          <SimpleBar style={{width:'100%',height:'100%'}}>
          <div className="settings-icon-container"> 
          <img src={infoIcon} title="info" id="settings-icon" style={{height:'16px',width:'16px'}} onClick={()=>settingsShow()}/>
        </div> 
           <div id="settings-page-view-container" style={{display:'flex',justifyContent:'center',width:'100%'}}>
          {renderView()}
          </div>
          {popUpShow==true && (
      <div ref={popUpContainer}>
        {<PopUp type={selectedType}></PopUp>}
        </div>)}  
      </SimpleBar>
        </div>
    )
    }}



function selectOption() {
return (
  <div className="right-container-se" id="selection" ref={settingsContainer}>
  <div className="content-name">{props.t('allgemein.auswahl')}</div>
  <div  className="content">

  <div className="content-group"> 
        <div className="content-group-name">{props.t('allgemein.einstellungen')}</div> 
      {/* <div className="einstellungen-container" onClick={()=>setSelectedType("countingAreaReset")} id="countingAreaReset">
      <img src={pic1} className="einstellungen-img" />
      {props.t('administration.countingAreaReset')}
      </div>
         <div className="einstellungen-container" onClick={()=>setSelectedType("carColors")} id="carColors">
         <img src={pic2} className="einstellungen-img" />
         {props.t('administration.carColors')}
         </div>   */}
             <div className="einstellungen-container" onClick={()=>setSelectedType("preCounter")} id="preCounter">
           <img src={pic3} className="einstellungen-img" />
           {props.t('administration.preCounterSettings')}
           </div> 
           {/* <div className="einstellungen-container" onClick={()=>setSelectedType("tags")} id="tags">
           <img src={pic3} className="einstellungen-img" />
           Tags
           </div> */}
        </div> 
        
        <div className="content-group"> 
        <div className="content-group-name">{props.t('administration.users')}</div> 
      <div className="einstellungen-container" onClick={()=>setSelectedType("createEditDeleteUser")} id="createEditDeleteUser">
      <img src={pic4} className="einstellungen-img" />
      {props.t('administration.createEditDeleteUser')}
      </div>
        </div> 
{/* 
        <div className="content-group"> 
        <div className="content-group-name">Gruppen / Typen</div> 
      <div className="einstellungen-container" onClick={()=>setSelectedType("editCustomerGroups")} id="editCustomerGroups">
      <img src={pic4} className="einstellungen-img" />
      Gruppen
      </div>
      <div className="einstellungen-container" onClick={()=>setSelectedType("editTypes")} id="editTypes">
      <img src={pic4} className="einstellungen-img" />
      Typen
      </div>
        </div>  
        
        
                 {mobile==false && (
        <div className="content-group"> 
        <div className="content-group-name">{props.t('administration.personalSettings.personalSettings')}</div> 
      <div className="einstellungen-container" onClick={()=>setSelectedType("emailNotifications")} id="emailNotifications">
      <img src={pic4} className="einstellungen-img" />
      {props.t('administration.personalSettings.emailBenachrichtigungen')}
      </div>
        </div> 
      )}   */}
        </div>
     </div>
)}

    return (
        <div className="pagewrapper-2">
          {colorPicker()}
        {/* {loaderShow==true && (
            <div className="loader">
               <ReactLoading type={"bars"} color={"#2a3042"} height={80} width={80} name={"Loading"} />
               <h4 style={{color:'#2a3042'}}>Loading</h4>
                 </div>
               )}   */}
      {optionView()}
      {selectOption()}          
       </div>
      )
      
      }  
      
export default withNamespaces()(Einstellungen)
