import React, { useState , useEffect } from 'react';
import {Alert} from "reactstrap";

import axios from "axios";
import { withNamespaces } from 'react-i18next';

import usFlag from "../../assets/images/flags/us.jpg";
import gerFlag from "../../assets/images/flags/germany.jpg";

import customStyles from '../../assets/select/customStyles'
import Select from "react-select";

import user from '../../assets/images/users/profile.png';

import { AvForm, AvField } from 'availity-reactstrap-validation';

const Profile = (props) => { 

    let link = localStorage.getItem('link')

    const [userName, setUserName] = useState(localStorage.getItem("userName"));

    const [changePWFailShow, setChangePWFailShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    // const [usernameCPW, setUsernameCPW] = useState();
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();

    // const optionsCustomerGroups = [
    //     { value: 'de', label: <div className="flex-iac settings-item"><img src={gerFlag} className="select-icon" style={{width:'22px'}}/> {props.t('allgemein.sprachen.de')}</div> },
    //     { value: 'en', label: <div className="flex-iac settings-item"><img src={usFlag} className="select-icon" style={{width:'22px'}}/> {props.t('allgemein.sprachen.en')}</div> },
    //   ];

    //   function usernameCPWChange(props) {
    //     setUsernameCPW(props) 
    //     setChangePWFailShow(false)
    // }
    
    function oldPasswordChange(props) {
        setOldPassword(props) 
        setChangePWFailShow(false)
    }

    function newPasswordChange(props) {
        setNewPassword(props) 
        setChangePWFailShow(false)
    }


    
    function  handleValidSubmit(e, v) {
        
        const data = {
            username:userName,
            oldPassword:oldPassword,
            newPassword:newPassword
        }
 
     axios.post(link+'/api/User/change-password', data, {
 
         headers: {
             'Content-Type': 'application/json',
         }
       
       }
       )  .then((response) => {
           
       })   
       .catch(error => {

        if (error.response.status=="400") {
        setErrorMessage(props.t('login.fehlerPasswortAenderung'))
        }
        else if (error.response.status=="500")(
         setErrorMessage(props.t('login.fehlerPasswortAenderung_2'))          
        )
        
           setChangePWFailShow(true)
       })
    }


function changePW() {
    return (
        <div>
        <div style={{width:'300px',marginLeft:'20px'}}> 
   
                         <AvForm className="form-horizontal" onValidSubmit={(e,v) => { handleValidSubmit(e,v) }} style={{opacity:'1'}}>

                             {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

                             <div className="form-group" style={{marginTop:'25px'}}>
                                 <AvField autoComplete="new-password" name="passwordOld" label={props.t('login.labelPasswortAlt')} value={oldPassword} type="password" required placeholder={props.t('login.labelPasswortAlt_description')}
                                  onChange={(e)=>oldPasswordChange(e.target.value)}/>
                             </div>

                             <div className="form-group" style={{marginTop:'25px'}}>
                                 <AvField autoComplete="new-password" name="passwordNew" label={props.t('login.labelPasswortNeu')} value={newPassword} type="password" required placeholder={props.t('login.labelPasswortNeu_description')}
                                  onChange={(e)=>newPasswordChange(e.target.value)}/>
                             </div>

                             {changePWFailShow==true && (
                             <div className="login-fail">{errorMessage}</div>
                             )}

                             <div className="mt-3 form-group">
                                 <button className="btn btn-primary btn-block waves-effect waves-light" type="submit" >{props.t('login.passwortAendern')}</button>
                             </div>

                         
                         </AvForm>

                         </div>        
      </div>
    )
}




        return (
        
            <div className="pagewrapper-2">
             <div className="left-container-se left-part-defects">

             <div className="flex-iac" style={{padding:'20px'}}>
            <img src={user} alt="" className="avatar-md rounded-circle img-thumbnail" style={{width:'60px',height:'60px'}}/>
            <div style={{fontSize:'20px',marginLeft:'10px'}}>{userName}</div>
            </div>

          {/* <div >{props.t('administration.benutzer.labelSprache')}:</div>
      <div style={{width:'100%',marginTop:'3px'}}>
     <Select closeMenuOnSelect={true} styles={customStyles} isSearchable={false} isMulti={false}  // onChange={changeTime}
 options={optionsCustomerGroups} defaultValue={optionsCustomerGroups[0]} theme={theme => ({...theme,borderRadius: 3, colors: {...theme.colors, primary: '#20a0ff',}, })} />
     </div> */}

            {changePW()}
            </div>
            </div>
           );
        }


export default withNamespaces()(Profile);

