import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { withNamespaces } from 'react-i18next';
import $ from "jquery";

import NumericInput from 'react-numeric-input';

let initialCG=[]
let modifiedCG=[]
let initialLT=[]
let modifiedLT=[]
let currentValue=null


    const SettingsBufferHysteresis = (props) =>  {

    let jwt=  localStorage.getItem('JWT')
    let link = localStorage.getItem('link')
    let customerGroups=JSON.parse(localStorage.getItem('customerGroups'))
    let types=JSON.parse(localStorage.getItem('lotTypes'))

    const [makeUpdate, setMakeUpdate] = useState();
    let data=props.data

    useEffect(() => {

      currentValue=null
      initialCG=[]
      modifiedCG=[]
      initialLT=[]
      modifiedLT=[]


      for (let i=0;i < customerGroups.length;i++) {

        let jsonObject={}

        jsonObject.customerGroup=customerGroups[i].id
        jsonObject.buffer=0
        jsonObject.hysteresis=0
  
        initialCG.push(jsonObject)
        modifiedCG.push({...jsonObject})
      }

      for (let i=0;i < types.length;i++) {

        let jsonObject={}

        jsonObject.lotType=types[i].id
        jsonObject.buffer=0
        jsonObject.hysteresis=0
  
        initialLT.push(jsonObject)
        modifiedLT.push({...jsonObject})
      }



    for (let i=0;i < data.customerGroupAreaValues.length;i++) {

      for (let j=0;j < initialCG.length;j++) {

      if (data.customerGroupAreaValues[i].customerGroup==initialCG[j].customerGroup){
        initialCG[j].buffer=data.customerGroupAreaValues[i].buffer
        initialCG[j].hysteresis=data.customerGroupAreaValues[i].hysteresis
      }
    }

    for (let j=0;j < modifiedCG.length;j++) {

      if (data.customerGroupAreaValues[i].customerGroup==modifiedCG[j].customerGroup){
        modifiedCG[j].buffer=data.customerGroupAreaValues[i].buffer
        modifiedCG[j].hysteresis=data.customerGroupAreaValues[i].hysteresis
      }
    }

    }

    for (let i=0;i < data.lotTypeAreaValues.length;i++) {

      for (let j=0;j < initialLT.length;j++) {

        if (data.lotTypeAreaValues[i].lotType==initialLT[j].lotType){
          initialLT[j].buffer=data.lotTypeAreaValues[i].buffer
          initialLT[j].hysteresis=data.lotTypeAreaValues[i].hysteresis
        }
      }
  
      for (let j=0;j < modifiedLT.length;j++) {

        if (data.lotTypeAreaValues[i].lotType==modifiedLT[j].lotType){
          modifiedLT[j].buffer=data.lotTypeAreaValues[i].buffer
          modifiedLT[j].hysteresis=data.lotTypeAreaValues[i].hysteresis
        }
      }

 
  }



  


  setMakeUpdate(current => !current)

    }, [props.data]);







      
  function updateBufferHysteresis() {

    for (let i=0;i < initialCG.length;i++) {
      for (let j=0;j < modifiedCG.length;j++) {
         if (initialCG[i].customerGroup==modifiedCG[j].customerGroup) {

          if (initialCG[i].buffer!=modifiedCG[j].buffer) {

      let type1="BufferCustomerGroup?"
      let type2="&customerGroupId="
      let type2_val=modifiedCG[j].customerGroup
      let type3="&buffer"
      let type3_val=modifiedCG[j].buffer

        axios.put(link+'/api/Area/'+type1+'elementId='+data.id+type2+type2_val+type3+'='+type3_val, {
          headers: {
            Authorization:'Bearer ' + jwt
         }
          })  .then((response) => {  
               initialCG[i].buffer=modifiedCG[j].buffer
               setMakeUpdate(current => !current)
          })
        
          .catch(error => {
         
         })

     }

     if (initialCG[i].hysteresis!=modifiedCG[j].hysteresis) {

      let type1="HysteresisCustomerGroup?"
      let type2="&customerGroupId="
      let type2_val=modifiedCG[j].customerGroup
      let type3="&hysteresis"
      let type3_val=modifiedCG[j].hysteresis

        axios.put(link+'/api/Area/'+type1+'elementId='+data.id+type2+type2_val+type3+'='+type3_val, {
          headers: {
            Authorization:'Bearer ' + jwt
         }
          })  .then((response) => {  
               initialCG[i].hysteresis=modifiedCG[j].hysteresis
               setMakeUpdate(current => !current)
          })
        
          .catch(error => {
         
         })

     }}}}

     for (let i=0;i < initialLT.length;i++) {
      for (let j=0;j < modifiedLT.length;j++) {
         if (initialLT[i].lotType==modifiedLT[j].lotType) {
          if (initialLT[i].buffer!=modifiedLT[j].buffer) {

      let type1="BufferLotType?"
      let type2="&lotTypeId="
      let type2_val=modifiedLT[j].lotType
      let type3="&buffer"
      let type3_val=modifiedLT[j].buffer

        axios.put(link+'/api/Area/'+type1+'elementId='+data.id+type2+type2_val+type3+'='+type3_val, {
          headers: {
            Authorization:'Bearer ' + jwt
         }
          })  .then((response) => {  
               initialLT[i].buffer=modifiedLT[j].buffer
          })
        
          .catch(error => {
         
         })

     }

     if (initialLT[i].hysteresis!=modifiedLT[j].hysteresis) {
      let type1="HysteresisLotType?"
      let type2="&lotTypeId="
      let type2_val=modifiedLT[j].lotType
      let type3="&hysteresis"
      let type3_val=modifiedLT[j].hysteresis

        axios.put(link+'/api/Area/'+type1+'elementId='+data.id+type2+type2_val+type3+'='+type3_val, {
          headers: {
            Authorization:'Bearer ' + jwt
         }
          })  .then((response) => {  
               initialLT[i].hysteresis=modifiedLT[j].hysteresis
          })
        
          .catch(error => {
         
         })

     }}}}

}


    function generateCustomerGroups() {
    let groupsArray=[]
    let customerGroupName

    for (let i=0;i < customerGroups.length;i++) {
      
    for (let j=0;j < modifiedCG.length;j++) {

       if (customerGroups[i].id==modifiedCG[j].customerGroup) {

        let id = customerGroups[i].id
        let buffer = modifiedCG[j].buffer
        let hysteresis = modifiedCG[j].hysteresis

        function passData(id,type) {
          for (let i=0;i < modifiedCG.length;i++) {
            if (modifiedCG[i].customerGroup==id) {
              if (type=="b") {modifiedCG[i].buffer=currentValue} 
              else if (type=="h") {modifiedCG[i].hysteresis=currentValue} 
            }
          }     
        }

        function onChange(e) {
          currentValue=e
          }   

          if (customerGroups[i].name.includes(".")) {
            customerGroupName=customerGroups[i].name
          }
          else {
            customerGroupName=props.t('elementeAllgemein.kundengruppen.'+customerGroups[i].name)
          }

      groupsArray.push(
        <div className="flex-sb" style={{marginTop:'3px'}}>
        <div>{customerGroupName}</div>
        <div className="flex-sb" style={{width:'150px'}}>
        <NumericInput className="input-numeric input70" id="BHCGB" onChange={e => {onChange(e); passData(id,"b")}} value={buffer} mobile={"auto"} min={0} max={99} />
        <NumericInput className="input-numeric input70" id="BHCGH" onChange={e => {onChange(e); passData(id,"h")}} value={hysteresis} mobile={"auto"} min={0} max={99} />
        </div>
        </div>
      )

    }
      }
    }

    return groupsArray
    }


    function generateTypes() {
      let typesArray=[]
      let typeName

      for (let i=0;i < types.length;i++) {

        for (let j=0;j < modifiedLT.length;j++) {

          if (types[i].id==modifiedLT[j].lotType) {
   
           let id = types[i].id
           let buffer = modifiedLT[j].buffer
           let hysteresis = modifiedLT[j].hysteresis

           function passData(id,type) {
            for (let i=0;i < modifiedLT.length;i++) {
              if (modifiedLT[i].lotType==id) {
                if (type=="b") {modifiedLT[i].buffer=currentValue} 
                else if (type=="h") {modifiedLT[i].hysteresis=currentValue} 
              }
            }
           
          }
  
          function onChange(e) {
              currentValue=e
            }  

            if (types[i].name.includes(".")) {
              typeName=types[i].name
            }
            else {
              typeName=props.t('elementeAllgemein.typen.'+types[i].name)
            }

        typesArray.push(
          <div className="flex-sb" style={{marginTop:'3px'}}>
          <div>{typeName}</div>
          <div className="flex-sb" style={{width:'150px'}}>
          <NumericInput className="input-numeric input70" id="BHLTB" onChange={e => {onChange(e); passData(id,"b")}} value={buffer} mobile={"auto"} min={0} max={99} />
          <NumericInput className="input-numeric input70" id="BHLTH" onChange={e => {onChange(e); passData(id,"h")}} value={hysteresis} mobile={"auto"} min={0} max={99} />
          </div>
          </div>
        )

      }
    }
      }
      return typesArray
      }

      function reset(type1,type2) {
        if (type1=="CG") {
          for (let i=0;i < modifiedCG.length;i++) {
            if (type2=="h") {
              modifiedCG[i].hysteresis=0
            }
            else {
              modifiedCG[i].buffer=0
            }
          }
        }
        else {
          for (let i=0;i < modifiedLT.length;i++) {
            if (type2=="h") {
              modifiedLT[i].hysteresis=0
            }
            else {
              modifiedLT[i].buffer=0
            }
          }
        }
         $('#BH'+type1+type2).val(0)
         currentValue=0
         setMakeUpdate(current => !current)
      }



    return (
      <div className="settings-groups"> 
<div className="settings-name">{props.t('elementeAllgemein.labelPufferHysterese')}</div> 
  <div className="flex-sb" style={{fontSize:'10px',marginBottom:'3px',fontWeight:'bold'}}>
  <div>{props.t('elementeAllgemein.kundengruppe')}</div>
  <div className="flex-sa" style={{width:'150px'}}>
  <div style={{width:'70px'}}>{props.t('elementeAllgemein.puffer')}</div>
  <div style={{width:'70px'}}>{props.t('elementeAllgemein.hysterese')}</div>
  </div>
  </div> 
   {generateCustomerGroups()}
  <div className="flex-sb" style={{fontSize:'10px',marginTop:'3px'}}>
  <div></div>
  <div className="flex-sb" style={{width:'150px'}}>
  <button class="button" style={{width:'70px',cursor:'pointer'}} onClick={()=>reset("CG","b")}>Reset</button>
  <button class="button" style={{width:'70px',cursor:'pointer'}} onClick={()=>reset("CG","h")}>Reset</button>
  </div>
  </div> 
  <div style={{marginTop:'20px'}}>
  <div className="flex-sb" style={{fontSize:'10px',marginBottom:'3px',fontWeight:'bold'}}>
  <div>{props.t('elementeAllgemein.typ')}</div>
  <div className="flex-sa" style={{width:'150px'}}>
  <div style={{width:'70px'}}>{props.t('elementeAllgemein.puffer')}</div>
  <div style={{width:'70px'}}>{props.t('elementeAllgemein.hysterese')}</div>
  </div>
  </div> 
   {generateTypes()}
  <div className="flex-sb" style={{fontSize:'10px',marginTop:'3px'}}>
  <div></div>
  <div className="flex-sb" style={{width:'150px'}}>
  <button class="button" style={{width:'70px',cursor:'pointer'}} onClick={()=>reset("LT","b")}>Reset</button>
  <button class="button" style={{width:'70px',cursor:'pointer'}} onClick={()=>reset("LT","h")}>Reset</button>
  </div>
  </div> 
  </div>
  <button class="button" style={{marginTop:'5px'}} onClick={updateBufferHysteresis}> {props.t('allgemein.speichern')}</button> 
    </div> 
    )
  }


  export default withNamespaces()(SettingsBufferHysteresis)

